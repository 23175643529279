
import axios from "axios";
import api_url from "./api_config";


const baseURL = api_url.PUBLISHER_URL;
const billingURL = api_url.BILLING_URL;

const countryApi = axios.create({
  baseURL:api_url.COUNTRIES_URL,
  headers:{"Content-type":"application/json"}
});
const api = axios.create({
  baseURL: billingURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchRevenueApi = (publisherId,data)=>{
  return api({
    url: `reports/publications-sales-reports/?id=${publisherId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",headers:{'Authorization':`Bearer ${data.token}`}
  });
}
export const fetchPublisherDownloadsApi = (publisherId, data) => {
  return api({
    url: `reports/publisher-downloads/?id=${publisherId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET", headers: {'Authorization': `Bearer ${data.token}`}
  });
}
export const fetchExchangeRatesApi=(base)=>{
    return api({
        url:`exchange_rates/${base}`,method:"GET"
    })
}
