import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

function IssueFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  return (
    <div className="row m-0">
      <div className="col-md-4">
        <div className="input-group input-group-merge input-group-reverse">
          <input
            type="text"
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            className="form-control"
            placeholder="Search Issues"
          />
          <div className="input-group-text" id="inputGroupReverse">
            <span className="fe fe-search"></span>
          </div>
        </div>
      </div>
      {/* 
        <div className="col-md-4">
          <select name="uses" className='form-select'>
            <option value="1">Last 28 Days</option>
            <option value="1">Last 14 Days</option>
            <option value="1">Last 7 Days</option>
            <option value="1">Today</option>
          </select>
        </div> */}
    </div>
  );
}

export default IssueFilter;
