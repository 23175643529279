import axios from "axios";
import api_config from "../api/api_config";

const baseURL = api_config.USERS_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchReadersApi = () => {
  return api({
    url: `readers`,
    method: "GET",
  });
};

export const fetchReaderDetailsApi = (id) => {
  return api({
    url: `readers/${id}`,
    method: "GET",
  });
};

export const createStaffsApi = (data) => {
  return api({
    url: `publishers/register`,
    data: data,
    method: "POST",
  });
};

export const fetchStaffsApi = (id) => {
  return api({
    url: `publishers/staff/${id}`,
    method: "GET",
  });
};

export const updateStaffsApi = (data, option) => {
  let headers = { contentType: "application/json" };
  return api({
    url: `auth/publishers/${data.id}`,
    data: data,
    method: "PUT",
    headers: headers,
  });
};

// archives
export const fetchReaderTransactionArchivesApi = (id) => {
  return api({
    url: `readers/archive/${id}/transaction-archive`,
    method: "GET",
  });
};

export const fetchReaderDownloadArchivesApi = (id) => {
  return api({
    url: `readers/archive/${id}/download-archive`,
    method: "GET",
  });
};

export const fetchReaderSubscriptionArchivesApi = (id) => {
  return api({
    url: `readers/archive/${id}/subscription-archive`,
    method: "GET",
  });
};

export const fetchTelcosApi = () => {
  return api({
    url: `telcos`,
    method: "GET",
  });
};

export const fetchTelcoDetailsApi = (id) => {
  return api({
    url: `telcos/${id}`,
    method: "GET",
  });
};

/*
  Admin apis
*/

export const fetchAdminsApi = (id) => {
  return api({
    url: `/publishers/staffs/${id}`,
    method: "GET",
  });
};

export const fetchAdminDetailsApi = (id) => {
  return api({
    url: `/publishers/${id}`,
    method: "GET",
  });
};

export const updateAdminApi = (id, data) => {
  return api({
    url: `/publishers/${id}`,
    data: data,
    method: "PUT",
  });
};

export const updateUserStatusApi = (data) => {
  return api({
    url: `/update-status`,
    data: data,
    method: "PATCH",
  });
};

export const deleteAdminApi = (id) => {
  return api({
    url: `admins`,
    data: id,
    method: "DELETE",
  });
};

export const updateReaderApi = (data) => {
  return api({
    url: `/update/`,
    data: data,
    method: "PATCH",
  });
};
