import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";

function StaffDetail(props) {
  // const data = staff
  var user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var staff = props.data;
  staff.first_name = staff.fullName.split(" ")[0];
  staff.last_name = staff.fullName.split(" ")[1];
  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("hello");
    // setLoading(true);
  };

  return (
    <div>
      <div className="me-2 text-success" onClick={handleShow}>
        View
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <div className="">
            <h6 className="header-pretitle">View</h6>

            <h1 className="header-title">{staff.last_name}'s Details</h1>
          </div>

          <hr />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input disabled
                    type="text"
                    className={"form-control"}
                    id="firstName"
                    placeholder="Enter First name"
                    autoFocus
                    defaultValue={staff.first_name}
                  />

                  <p className="errors">{}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    Last Name
                  </label>
                  <input disabled
                    type="text"
                    className={"form-control"}
                    id="lastName"
                    placeholder="Enter Last name"
                    defaultValue={staff.last_name}
                  />

                  <p className="errors">{}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input disabled
                    type="email"
                    className={"form-control"}
                    id="email"
                    placeholder="Enter Email Address"
                    defaultValue={staff.email}
                  />

                  <p className="errors">{}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    Phone Number
                  </label>
                  <input disabled
                    type="text"
                    className={"form-control"}
                    id="phone"
                    placeholder="Enter Phone Number"
                    defaultValue={staff.phone}
                  />

                  <p className="errors">{}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="role" className="form-label">
                    Role
                  </label>
                  <select className="form-control" defaultValue={staff.role} disabled>
                    <option value="7">Finance</option>
                    <option value="6">Uploader</option>
                    <option value="2">Adminstraror</option>
                  </select>

                  <p className="errors">{}</p>
                </div>
              </div>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default StaffDetail;
