import React, { useState } from 'react';
import { useAsyncDebounce } from "react-table";

function PublicationFilter({
  onChange,filter
}) {

  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(filter);
  const handleSearch = ( e ) => {
    const search = e.target.value.trim() 
      setValue( search);
      onChange( search )
    
  }
  return (
      <div className='row m-0'>
        
        <div className="col-md-4">
          <div className='input-group input-group-merge input-group-reverse'>
            <input  type="text" 
                    defaultValue={value || ""}
            onChange={handleSearch}
                    className='form-control' placeholder='Search Publications' />
            <div className="input-group-text" id="inputGroupReverse">
              <span className="fe fe-search"></span>
            </div>
          </div>
        </div>

        {/* <div className="col-md-4">
          <select name="uses" className='form-select'>
            <option value="1">Last 28 Days</option>
            <option value="1">Last 14 Days</option>
            <option value="1">Last 7 Days</option>
            <option value="1">Today</option>
          </select>
        </div> */}

      </div>
  );
}

export default PublicationFilter;
