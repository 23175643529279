import React, { useState } from "react";
// import { identifier } from "@babel/types";
import { updateStaffsApi } from "../../../../api/user";
import CustomOption from "./customOption";

const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));

export const STAFFCOLUMNS = [
  {
    header: "id",
    accessor: "id",
    Cell: (props) => <div>{props.row.index + 1}</div>,
  },
  {
    header: "full name",
    accessor: "fullName",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    header: "email",
    accessor: "email",
  },
  {
    header: "mobile",
    accessor: "phone",
  },
  {
    header: "role",
    accessor: "role",
    Cell: (props) => (
      <div className="text-capitalize">{translateRole(props.value)}</div>
    ),
  },
  {
    header: "status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus(props.value)}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
     <CustomOption {...props}/>
    ),
  },
];

// const handleDeactivate = (id) => {
//   updateStaffsApi({ status: "INACTIVE", id: id }, { authorization: user.token })
//     .then((result) => {
//       console.log("HELLO: ", result.data);
//     })
//     .catch((e) => {
//       console.log("YYYYYY: ", e);
//     });
// };

const handleDelete = (id) => {
  // updateStaffsApi({ status: "DELETE", id: id }, { authorization: user.token })
  //   .then((result) => {
  //     console.log("HELLO: ", result.data);
  //   })
  //   .catch((e) => {
  //     console.log("YYYYYY: ", e);
  //   });
  // confirm(id);
};
const translateRole = (roleId) => {
  let roles = [
    { id: 2, role: "Admin" },
    { id: 6, role: "Uploader" },
    { id: 7, role: "Finance" },
  ];
  let role = roles.find((r) => r.id == roleId);
  return role ? role.role : roleId;
};

function checkStatus(status) {
  if (status.toUpperCase() == "ACTIVE") {
    return (
      <div className="status">
        <div className="dot"></div>
        {status.toUpperCase()}
      </div>
    );
  } else {
    return (
      <div className="status">
        <div className="dot inactive"></div>
        {status.toUpperCase()}
      </div>
    );
  }
}
