import React from 'react'

import  { Tabs, Tab } from 'react-bootstrap';

function UserDetailNavs(props) {

  console.log(props)
  return (
      <Tabs
        defaultActiveKey="transaction"
        transition={false}
        id="userDetailNavs"
        className="mb-3"
      >
        
      </Tabs>
  )
}

export default UserDetailNavs
