import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useParams} from "react-router-dom";
import DashboardWrapper from '../../../components/DashboardWrapper';
import { fetchAdminDetails } from '../../../redux-store/Actions/admin-action';
import UserDetailNavs from './userDetailNavs';
import Loader from 'react-loader-spinner';

function UserDetails() {


  var navigate = useNavigate();
  const dispatch = useDispatch();
  const params   = useParams();
  const id = params.id
  console.log("params: ",id);

  let options = {year: 'numeric', month: 'short', day: '2-digit' };


  // const { readers, isLoading } = useSelector(
  //   (state) => state.readers 
  // );  

  const { admin, isLoading } = useSelector(
    (state) => state.admin
  );  

  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }


  useEffect(() => {
    dispatch(fetchAdminDetails(id));
  }, [dispatch]);

  return (
      <DashboardWrapper>
         <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">

              <div className="header mt-md-3">
                <nav aria-label="breadcrumb mb-2">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/staffs" onClick={toPage("/staffs")} >Staff</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">View</li>
                  </ol>
                </nav>
              </div>

            

              { isLoading ? (
                <div className="content">
                  <div className="m-4 center">
                    <Loader
                      type="TailSpin" 
                      color="#2BC38C"
                      height={50} 
                      width={25} />
                  </div>
                </div>
              ) : (

                <div>
                { admin && (

                  <div className="content">
                    <div className="row mt-3">

                    
                      <div className="col-md-7">

                          <div className="card mt-2">
                            <div className="px-4 pt-4">
                              <div className="row">
                                <div className="col">

                                  <h1 className="header-title">
                                    User Overview
                                  </h1>
                                </div>


                              </div>

                              
                              <hr />


                            </div>


                            { admin &&(

                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="user-details-image">
                                    <div className="udi-avatar">
                                      <img src="/avatar.png" alt="" />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-8">
                                  <p className='ud-p'>{ admin.fullName}</p>
                                  <p className='ud-p'>Reference Number : <span className='text-success bold'>{ admin.refNo}</span></p>
                                  <p className='ud-p'>Account Created : <span className='text-success'> { new Date(admin.createdAt).toLocaleString('en-US', options)}</span></p>
                                  <div className='d-flex ud mt-3'>
                                    <div className='mr-2'>
                                      <i className='fe fe-smartphone'></i> { admin.phone }
                                    </div>

                                    <div className='mr-2'>
                                      <i className='fe fe-mail'></i> { admin.email}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            )}
                          </div>

                      </div>

                     
                    </div>
                    <UserDetailNavs id={id} />
                  </div>

                )}

                </div>
              )}

            </div>
          </div>
          </div>

      </DashboardWrapper>
  );
}

export default UserDetails;
