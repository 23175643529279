import React, { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Loader from 'react-loader-spinner';
import DashboardWrapper from '../../../../components/DashboardWrapper';
import { fetchCompanyDetails } from '../../../../redux-store/Actions/company-action';
import { useDispatch, useSelector } from 'react-redux';
import { createIssuesApi } from '../../../../api/publisher';
// import COUNTRIES from '../../../../config/COUNTRIES.json'
import PUBLICATIONFREQUENCY from '../../../../config/PublicationFrequency.json';
import { toast } from 'react-toastify';
import { fetchGenres } from '../../../../redux-store/Actions/genre-action';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import api_config from '../../../../api/api_config';

const schema = yup
  .object({
    title: yup.string().required().min(3).max(255).label(''),
  })
  .required();

export default function Create() {
  var companyId = JSON.parse(localStorage.getItem('RIFALYPUBLISHER')).companyId;
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.company);
  const [country, setCountry] = useState({
    countryCode: 'TZ',
    name: 'Tanzania',
    currency: 'TZS',
  });
  const [selectedGenre, setSelectedGenre] = useState([]);

  const { genres } = useSelector((state) => state.genres);

  const {
    register,
    handleSubmit,
    handleInputChange,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const countries =
    localStorage.getItem('RIFALYCOUNTRIES') &&
    localStorage.getItem('RIFALYCOUNTRIES') != 'undefined'
      ? JSON.parse(localStorage.getItem('RIFALYCOUNTRIES'))
      : [];

  const [targetType, setTargetType] = useState('global');
  // const [restrict, setRestrict] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [frequency, setFrequency] = useState();

  // const countries = useMemo(() => COUNTRIES, [])
  const publicationFrequency = useMemo(() => PUBLICATIONFREQUENCY, []);

  const navigate = useNavigate();

  function changeTargetCountry(e) {
    if (targetType === 'global') {
    }
    console.log(targetType);
  }

  const handleCountryChange = (e) => {
    let c = countries.find((c) => c.countryCode == e.target.value);
    setCountry(c);
  };

  const onSubmit = (data, e) => {
    setLoading(true);
    data.genreId = selectedGenre.map((g) => g.id);
    data.country = country.countryCode;
    data.currency = country.currency;
    console.table(data);

    createIssuesApi(data)
      .then((res) => {
        setLoading(false);
        e.target.reset();

        toast.success('Issue Created successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        console.log(res.data.data);
        navigate('/publications/issues');
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    setLoading(false);
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate('/dashboard');
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    dispatch(fetchGenres());
    dispatch(fetchCompanyDetails(companyId));
  }, [dispatch]);

  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3 mb-5'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item'
                    aria-current='page'>
                    <a
                      href='/publications'
                      onClick={toPage('/publications')}>
                      Publications
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item'
                    aria-current='page'>
                    <a
                      href='/publications/issues'
                      onClick={toPage('/publications/issues')}>
                      Issues
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    Create Issue
                  </li>
                </ol>
              </nav>
            </div>

            <div className='row mt-3'>
              <div className='col-md-12'>
                <div className='card pt-3'>
                  <div className='px-4 pt-3'>
                    <div className=''>
                      <h6 className='header-pretitle'>Overview</h6>

                      <h1 className='header-title'>New Issue</h1>
                    </div>

                    <hr />
                  </div>

                  <div className='card-body'>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className='p-3'>
                      <div className='row g-3 mb-3'>
                        <div className='col-12 col-md-4 mb-1'>
                          <label
                            htmlFor='company'
                            className='form-label'>
                            Publisher Company
                          </label>
                          <select
                            className='form-select'
                            autoFocus
                            value={company.id}
                            {...register('publisherId')}>
                            <option
                              key={company.id}
                              value={company.id}>
                              {company.companyName}{' '}
                            </option>
                          </select>

                          <p className='errors'>{errors.company?.message}</p>
                        </div>

                        <div className='col-12 col-md-4 mb-1'>
                          <label
                            htmlFor='title'
                            className='form-label'>
                            Issue Title
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='validationServer02'
                            placeholder='Enter Issue Title'
                            required
                            {...register('title')}
                          />
                        </div>

                        <div className='col-12 col-md-4 mb-1'>
                          <label
                            htmlFor='category'
                            className='form-label'>
                            Category
                          </label>
                          <select
                            {...register('categoryId')}
                            onChange={handleInputChange}
                            className='form-select'>
                            <option disabled>Select Issue Category</option>
                            <option value='1'>NewsPaper</option>
                            <option value='2'>Magazine</option>
                            <option value='3'>Book</option>
                            <option value='4'>Article</option>
                          </select>
                        </div>

                        <div className='col-12 col-md-4 mb-1'>
                          <label
                            htmlFor='language'
                            className='form-label'>
                            Language
                          </label>
                          <select
                            {...register('language')}
                            className='form-select'>
                            <option disabled>Select Issue Language</option>
                            <option value='EN'>English</option>
                            <option value='SW'>Swahili</option>
                          </select>
                        </div>

                        <div className='col-12 col-md-4 mb-1'>
                          <label
                            htmlFor='price'
                            className='form-label'>
                            Price
                          </label>
                          <input
                            type='number'
                            {...register('price')}
                            className='form-control'
                            placeholder='Price'
                          />
                        </div>

                        <div className='col-12 col-md-4 mb-1'>
                          <label
                            htmlFor='country'
                            className='form-label'>
                            Country
                          </label>
                          <select
                            {...register('country')}
                            value={country.countryCode}
                            className='form-select'
                            onChange={handleCountryChange}>
                            <option disabled>Select Country</option>
                            {countries.map((country) => (
                              <option
                                value={country.countryCode}
                                key={country.countryCode}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className='col-12 col-md-4 mb-2'>
                          <label
                            htmlFor='currency'
                            className='form-label'>
                            Currency
                          </label>
                          <select
                            {...register('currency')}
                            className='form-select'
                            value={country.currency}>
                            {countries.map((c) => {
                              return (
                                <option
                                  key={c.countryCode}
                                  value={c.currency}>
                                  {c.currency}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className='col-12 col-md-4 mb-1'>
                          <label
                            htmlFor='frequency'
                            className='form-label'>
                            Publication Frequency *
                          </label>
                          <select
                            name='frequency'
                            {...register('frequency')}
                            className='form-control'>
                            <option disabled>Select Frequency</option>
                            {publicationFrequency.map((frequency) => (
                              <option
                                key={frequency.id}
                                value={frequency.title}>
                                {frequency.title}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className='col-12 col-md-4 mb-1'>
                          <label
                            htmlFor='availability'
                            className='form-label'>
                            Publication Availability
                          </label>
                          <div className='checklis'>
                            <div className='form-check'>
                              <label
                                className='form-check-label'
                                onClick={() => {
                                  setTargetType('global');
                                }}>
                                <input
                                  className='form-check-input'
                                  name='country'
                                  value='global'
                                  onChange={changeTargetCountry()}
                                  type='radio'
                                  id='checklistOne'
                                />
                                Global
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-md-4 mb-1'>
                          <label
                            htmlFor='genreId'
                            className='form-label'>
                            Select Genre
                          </label>
                          <MultiSelect
                            options={genres.map((g) => {
                              g.label = g.name;
                              g.value = g.id;
                              return g;
                            })}
                            value={selectedGenre}
                            onChange={setSelectedGenre}
                            labelledBy='Select'
                          />
                        </div>
                      </div>
                      <div className='row mt-3'>
                        <div className='col'></div>
                        <div className='col-auto'>
                          {loading ? (
                            <span className='btn btn-primary'>
                              <Loader
                                type='TailSpin'
                                color='#ffffff'
                                height={20}
                                width={20}
                              />
                            </span>
                          ) : (
                            <button
                              className='btn btn-primary'
                              type='submit'>
                              Submit
                            </button>
                          )}
                          {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
