import { fetchReadersApi, fetchReaderDetailsApi } from "../../api/user";

export const FETCH_READERS_LOADING = "FETCH_READERS_LOADING";
export const FETCH_READERS_SUCCESS = "FETCH_READERS_SUCCESS";
export const FETCH_READER_DETAILS_LOADING = "FETCH_READER_DETAILS_LOADING";
export const FETCH_READER_DETAILS_SUCCESS = "FETCH_READER_DETAILS_SUCCESS";



export const fetchReaders = () => {
  return (dispatch) => {
    dispatch(fetchReadersLoading());
    return fetchReadersApi()
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchReadersSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};



export const fetchReaderDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchReaderDetailsLoading());
    return fetchReaderDetailsApi(id)
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchReaderDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


//  staff 

const fetchReadersLoading = () => {
  return {
    type: FETCH_READERS_LOADING,
  };
};


const fetchReadersSuccess = (data) => {
  return {
    type: FETCH_READERS_SUCCESS,
    payload: data,
  };
};


//  staff details

const fetchReaderDetailsLoading = () => {
  return {
    type: FETCH_READER_DETAILS_LOADING,
  };
};

const fetchReaderDetailsSuccess = (data) => {
  return {
    type: FETCH_READER_DETAILS_SUCCESS,
    payload: data,
  };
};
