import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { updatePublisherBillingApi } from "../../../../api/company";
import Loader from "react-loader-spinner";

const EditBillingForm=(props)=>{
   
      
    
    const [isLoading,setIsLoading] = useState(false);
    const [error,setError] = useState("")
    var company = props.company;

    const handleSubmit=(e)=>{
        e.preventDefault();
        setError("")
        setIsLoading(true);
        const form = e.target;
        let data = {
            bankName:form.bankName.value.trim(),
            bankBranch:form.bankBranch.value.trim(),
            accountName:form.accountName.value.trim(),
            accountNumber:form.accountNumber.value,
            swiftCode:form.swiftCode.value.trim(),
            companyId:company.id
        }
        // if(props.coverFile) data.coverFile = props.coverFile;
console.log("x: ",data)
        updatePublisherBillingApi(data).then(result=>{
            console.log("result: ",result);
            props.onSave(result.data.data)
        }).catch(e=>{
            console.log("eeer: ",e);
            setError("something went wrong")
        })
        .finally(()=>{
            setIsLoading(false);
            
        })

    }

  
    return(
        <section className="detail-section mt-4">
        <div className="detail-title">
          <div className="row">
            <div className="col">
              <h3>Billing Information</h3>
            </div>

          </div>
        </div>
        <form onSubmit={handleSubmit}>
            <div className="detail-contents">
          <div className="row">

            <div className="col-md-4">
              <div className="dc-title">
                Bank Name
              </div>
              <div className="dc-body">
                <input type="text" className="form-control" name="bankName" defaultValue={ company.publisher_billing_info ? company.publisher_billing_info.bankName:""} />
              </div>

            </div>


            <div className="col-md-4">
              <div className="dc-title">
                Bank Branch
              </div>
              <div className="dc-body">
              <input type="text" className="form-control" name="bankBranch" defaultValue={company.publisher_billing_info ? company.publisher_billing_info.bankBranch:""}/>
              </div>

            </div>


            <div className="col-md-4">
              <div className="dc-title">
                Account Name
              </div>
              <div className="dc-body">
              <input type="text" className="form-control" name="accountName" defaultValue={company.publisher_billing_info ? company.publisher_billing_info.accountName:""}/>
              </div>

            </div>


            <div className="col-md-4">
              <div className="dc-title">
                Account Number
              </div>
              <div className="dc-body">
              <input type="text" className="form-control" name="accountNumber" defaultValue={company.publisher_billing_info ? company.publisher_billing_info.accountNumber:""}/>
              </div>

            </div>


            <div className="col-md-4">
              <div className="dc-title">
                Swift Code
              </div>
              <div className="dc-body">
              <input type="text" className="form-control" name="swiftCode" defaultValue={company.publisher_billing_info ? company.publisher_billing_info.swiftCode:""}/>
              </div>

            </div>
            <div className="col-md-4">
              <div className="dc-title">
                
              </div>
              <div className="dc-body">
              {/* <input type="text" className="form-control" name="swiftCode" defaultValue={company.publisher_billing_info ? company.publisher_billing_info.swiftCode:""}/> */}
              </div>

            </div>
            
            <div className="col-md-4">
          <div className="dc-body">{ isLoading ? (
                <span className="btn btn-primary">
                  <Loader
                    type="TailSpin" 
                    color="#ffffff"
                    height={20} 
                    width={20} />
                </span>
              ) :
            <input type="submit" name="submit" className="btn btn-primary" value={"SAVE"}/>}
          </div>

        </div>
        <div className="col-md-4">
          <div className="dc-title text-danger">
            {error}
          </div>
         
        </div>
          </div>
        </div>
</form>
      </section>)
}
export default EditBillingForm;