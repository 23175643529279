import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { updateStaffsApi } from "../../../../api/user";
import Loader from "react-loader-spinner";
const ConfirmDialog = (props) => {
  const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));
  const [showDialog, setShowDialog] = useState(props.show);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const deleteUser = () => {
    setIsLoading(true);
    updateStaffsApi(
      { status: "DELETE", id: props.id },
      { authorization: user.token },
    )
      .then((result) => {
        console.log("HELLO: ", result.data);
        setShowDialog(false);
        navigate("/staffs", { replace: true });
      })
      .catch((e) => {
        console.log("YYYYYY: ", e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="modal show" style={{ zIndex: "9999999999999999999" }}>
      <Modal
        show={showDialog}
        size="md"
        onHide={() => setShowDialog(false)}
        role="dialog">
        <Modal.Header closeButton>
          <Modal.Title>{"Confirm " + props.action}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete {props.name}?</Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <Loader type="TailSpin" color="#2BC38C" height={20} width={20} />
          ) : (
            <>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setShowDialog(false);
                }}>
                Close
              </button>{" "}
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteUser}>
                {props.action.toUpperCase()}
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ConfirmDialog;
