import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { ISSUECOLUMNS } from "./IssueColumns";
import { fetchIssues } from "../../../../redux-store/Actions/issue-action";
import EmptyData from "../../../../components/EmptyData/Index";
import IssueFilter from "./IssueFilter";
import { AnimatePresence, motion } from "framer-motion";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";

function IssuesTable() {
  const columns = useMemo(() => ISSUECOLUMNS, []);
  var user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));
  const dispatch = useDispatch();

  const [countries, setCountries] = useState([]);
  const { issues } = useSelector((state) => state.issues);
  const isAdmin = parseInt(user.role) === 2;

  const data = issues;
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,

    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Rifaly_Issues ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };
  useEffect(() => {
    const companyId = JSON.parse(
      localStorage.getItem("RIFALYPUBLISHER")
    ).companyId;
    window.scrollTo(0, 0);
    dispatch(fetchIssues(companyId));
    fetch("https://billing.rifaly.co.tz/billing/countries/all", {
      method: "GET",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        setCountries(result.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [dispatch]);

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    []
  );

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col">
          <IssueFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        </div>
        {isAdmin ? (
          <div className="col-auto">
            <Link
              to="/publications/issues/create"
              className="btn btn-primary lift"
            >
              <span>
                <i className="fe fe-plus"></i>
              </span>
              Create Issue
            </Link>
          </div>
        ) : null}
      </div>

      <div className="row mt-2 body-content">
        {issues && issues.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Registered Issues</h4>

                    <div>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      <AnimatePresence>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps({
                                layouttransition: spring,
                                exit: { opacity: 0, maxHeight: 0 },
                              })}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <motion.td
                                    {...cell.getCellProps({
                                      layouttransition: spring,
                                      exit: { opacity: 0.5, maxHeight: 0 },
                                    })}
                                  >
                                    {cell.render("Cell")}
                                  </motion.td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} to{" "}
                    {pageIndex > 10
                      ? issues.length
                      : pageIndex * pageSize + pageSize > issues.length
                      ? issues.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {issues.length} elements
                  </span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => previousPage()}
                  >
                    <i className="fe fe-chevron-left"></i>
                  </button>
                  <span className="table-stat">Page {pageIndex + 1}</span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => nextPage()}
                  >
                    <i className="fe fe-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name="Issues" path="/publications/issues/create" />
        )}
      </div>
    </div>
  );
}

export default IssuesTable;
