import React, { useState, useCallback} from 'react'
import  DashboardWrapper  from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { createPublisherApi } from "../../../../api/company";
import Loader from "react-loader-spinner";
import Dropzone from 'react-dropzone'
import COUNTRIES from "../../../../config/COUNTRIES.json";


const schema = yup.object({
  companyName: yup.string().strict(false).trim().required().max(255).label("Company Name"),
  commission: yup.number().required().min(0).max(100),
  bankName:yup.string().strict(false).trim().required().max(255).label('Bank Name'),
  bankBranch:yup.string().strict(false).trim().required().max(255).label('Branch Name'),
  accountName: yup.string().strict(false).trim().required().label('Account Name'),
  accountNumber: yup.string().strict(false).trim().required().label('Account Number'),
  accountType: yup.string().strict(false).trim().required().label('Account Type'),
  swiftCode: yup.string().strict(false).trim().required().label('Swift Code'),
  tin: yup.string().strict(false).trim().required().max(60).label("Tin Number")
}).required();



function CreateCompany() {

  const { register, 
          handleSubmit,
          formState:{ errors }
        } = useForm({
          resolver: yupResolver(schema)
        });

  const [loading, setLoading] = useState(false);

  const [businessLicence, setBusinessLicence] = useState({});
  const [publishingCertificate, setPublishingCertificate] = useState({});
  const [incoporationCertificate, setIncoporationCertificate] = useState({});
  const [nationalIdLetter, setNationalIdLetter] = useState({});
  const [loadedFile, setLoadedFile] = useState({});
  const countries = COUNTRIES;
  // const countries = useMemo(() => [COUNTRIES]);


  const onDropBusinessLicence = useCallback(uploadedFiles => {
    readFiles(uploadedFiles, 1)
  }, [])

  const onDropPublishingCertificate = useCallback(uploadedFiles => {
    readFiles(uploadedFiles, 2)
  }, [])

  const onDropIncoporationCertificate = useCallback(uploadedFiles => {
    readFiles(uploadedFiles, 3)
  }, [])

  const onDropNationalIdLetter = useCallback(uploadedFiles => {
    readFiles(uploadedFiles, 4)
  }, [])

  const onDropCover = useCallback(uploadedFiles => {
    readFiles(uploadedFiles, 5)
  }, [])



  function readFiles(files, type){

    console.log(files)
    files.forEach(file => {

      
      const reader = new FileReader()


      reader.onabort = () =>
            console.log('file reader aborted')

        reader.onerror = () =>
            console.log('file reader failed')

        reader.onload = () => {
            const dataUrl = reader.result
            console.log(dataUrl)

            switch (type) {
              case 1:
                setBusinessLicence({
                  url: dataUrl
                })
                break;

              case 2:
                setPublishingCertificate({
                  url: dataUrl
                })
                break;

              case 3:
                setIncoporationCertificate({
                  url: dataUrl
                })
                break;

              case 4:
                setNationalIdLetter({
                  url: dataUrl
                })
                break;

              case 5:
                setLoadedFile({
                  url: dataUrl
                });
                break;
            
              default:
                break;
            }
            
        }

        // Read the file as a data url
      reader.readAsDataURL(file)


    }) 
  }

  // handle su 
  const onSubmit = data => {
    setLoading(true)
    // alert(data.companyName)


    data.businessLicence = businessLicence;
    data.publishingCertificate = publishingCertificate;
    data.incoporationCertificate = incoporationCertificate;
    data.nationalIdLetter = nationalIdLetter;
    data.coverImage = loadedFile; 
  
    createPublisherApi(data).then(res => {
      console.log(res.data.data.insertId)
      setLoading(false)
      var path = "/publishers/company/"
      navigate(path);
    
    }).catch(error => {
      setLoading(false)
      console.log(error)
    })

    console.log(data)
  };

  const navigate = useNavigate();
  
  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }




  return (
    <DashboardWrapper>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
       
        <div className="header mt-md-3">

          <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/publishers")} >Publishers</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/publishers/company")} >Companies</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Create Company</li>
                </ol>
          </nav>

        </div>


        <div className="content">
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="card pt-3">

                <div className="px-4 pt-4">
                  <div className="">

                      <h6 className="header-pretitle">
                        Overview
                      </h6>


                      <h1 className="header-title">
                        Create Company
                      </h1>

                  </div>

                  <hr />


                  <div className="row">
                    <div className="col-md-12">
                      <form onSubmit={handleSubmit(onSubmit)}>

                      <div className="row mb-3">
                            <div className="col-md-6">
                              
                              <Dropzone onDrop={onDropCover} multiple={false} accept=".png">
                                {({getRootProps, getInputProps}) => (
                                  <section>
                                    <div className="mb-1">
                                      <label className="form-label">Company Cover</label>  
                                    </div>
                                    <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                                      <input {...getInputProps()} />
                                      <div className="upload-content p-4">
                                        <i className="fe fe-upload-cloud"></i>
                                        <p>Drag and drop or click to upload</p>
                                        <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                                      </div>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>

                              <aside className='file-preview'>
                                { loadedFile && (
                                  <div>
                                    { loadedFile.name }
                                  </div>
                                ) }
                              </aside>
                                    
                            </div>
                          </div>



                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-head">
                              <p className="form-title">Basic Information</p>
                            </div>
                          </div>


                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="company" className="form-label">Company Name</label>
                              <input type="text" 
                                     className={ errors.companyName ? "form-control is-invalid" : "form-control"}  
                                     id="company_name" 
                                     placeholder="Enter Company name"
                                     autoFocus
                                     required
                                     {...register("companyName")} />

                                <p className="errors">{errors.companyName?.message}</p>
                         
                             
                            </div>
                          </div>


                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="company" className="form-label">Company Size (Employees)</label>
                              <select className="form-select form-control"
                                      { ...register("companySize")} required>
                                <option>Select Company Size</option>
                                <option value="1">1 - 10 </option>
                                <option value="2">11 - 20 </option>
                                <option value="3">20 and above </option>
                              </select>
                            
                            </div>
                          </div>   



                           <div className="col-md-4">
                            <div className="form-group">
                              <label for="commision" className="form-label">Commission (%)</label>
                              <input type="number" 
                                     className={ errors.commission ? "form-control is-invalid" : "form-control"} 
                                     id="commision" 
                                     { ...register('commission')}
                                     placeholder="Enter Commision Percentage"
                                     required />

                              <p className="errors">{errors.commission?.message}</p>

                            </div>
                          </div>




                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="address" className="form-label">Company Address</label>
                              <input type="text" 
                                     className="form-control" 
                                    id="address" 
                                    { ...register('address')}
                                    required
                                    placeholder="Enter Company Address" />
                            </div>
                          </div> 


                         


                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="country" className="form-label">Country</label>


                             <select className='form-select' { ...register('country') }>
                               <option value="" selected disabled>Select Country</option>
                               { countries.map((country) => (
                                 <option value={country.code}>{country.name}</option>
                               ))}
                             </select>
                            </div>
                          </div>


                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="city" className="form-label">City</label>
                              <input type="text" 
                                     className="form-control" 
                                     id="city" 
                                     { ...register('city')}
                                     required
                                     placeholder="Enter City" />
                            </div>
                          </div>



                        



                          <div className="col-md-12">
                            <div className="form-head">
                              <p className="form-title">Business Information</p>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label for="company" className="form-label">Enter Company’s TIN Number  *</label>
                              <input type="text" 
                                     className={ errors.tin ? "form-control is-invalid" : "form-control"}  
                                     id="tin" 
                                     { ...register('tin') }
                                     required
                                     placeholder="Enter your company’s TIN Number" />
                              <p className="errors">{errors.tin?.message}</p>
                            </div>
                          </div>


                          <div className="col-md-6">


                      
                            <Dropzone onDrop={onDropBusinessLicence} multiple={false} accept=".pdf">
                                {({getRootProps, getInputProps}) => (
                                  <section>
                                    <div className="mb-1">
                                      <label className="form-label">Upload a copy of your Business Licence</label>  
                                    </div>
                                    <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                                      <input {...getInputProps()} />
                                      <div className="upload-content p-4">
                                        <i className="fe fe-upload-cloud"></i>
                                        <p>Drag and drop or click to upload</p>
                                        <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                                      </div>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>


                          </div>


                          <div className="col-md-6">
                                  
                              <Dropzone onDrop={onDropPublishingCertificate} multiple={false} accept=".pdf">
                                {({getRootProps, getInputProps}) => (
                                  <section>
                                    <div className="mb-1">
                                      <label className="form-label">Upload a copy of your Publishing Certificate Licence</label>  
                                    </div>
                                    <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                                      <input {...getInputProps()} />
                                      <div className="upload-content p-4">
                                        <i className="fe fe-upload-cloud"></i>
                                        <p>Drag and drop or click to upload</p>
                                        <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                                      </div>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>


                          </div>


                          <div className="col-md-6">
                                  
                                  <Dropzone onDrop={onDropIncoporationCertificate} multiple={false} accept=".pdf">
                                    {({getRootProps, getInputProps}) => (
                                      <section>
                                        <div className="mb-1">
                                          <label className="form-label">Upload a copy of your Certificate of Incorporation</label>  
                                        </div>
                                        <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                                          <input {...getInputProps()} />
                                          <div className="upload-content p-4">
                                            <i className="fe fe-upload-cloud"></i>
                                            <p>Drag and drop or click to upload</p>
                                            <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                                          </div>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
    
    
                              </div>


                              <div className="col-md-6">
                                  
                                  <Dropzone onDrop={onDropNationalIdLetter} multiple={false} accept=".pdf">
                                    {({getRootProps, getInputProps}) => (
                                      <section>
                                        <div className="mb-1">
                                          <label className="form-label">Upload a copy of your National Identification Letter</label>  
                                        </div>
                                        <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                                          <input {...getInputProps()} />
                                          <div className="upload-content p-4">
                                            <i className="fe fe-upload-cloud"></i>
                                            <p>Drag and drop or click to upload</p>
                                            <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                                          </div>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
    
    
                              </div>











                          <div className="col-md-12">
                            <div className="form-head">
                              <p className="form-title">Billing Information</p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="bankName" className="form-label">Bank Name*</label>
                              <input type="text" 
                                     className= { errors.bankName? "form-control is-invalid" : "form-control"} 
                                     id="bankName" 
                                     { ...register('bankName') }
                                     required
                                     placeholder="Enter Bank Name" />

                              <p className="errors">{errors.bankName?.message}</p>
                            </div>
                          </div>


                      


                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="bankBranch" className="form-label">Bank Branch*</label>
                              <input type="text" 
                                     className={ errors.bankBranch? "form-control is-invalid" : "form-control"} 
                                    id="bankBranch" 
                                    { ...register('bankBranch')}
                                    required
                                    placeholder="Enter Bank Branch" />

                              <p className="errors">{errors.bankBranch?.message}</p>
                            </div>
                          </div>



                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="accountName" className="form-label">Account Name*</label>
                              <input type="text" 
                                     className={ errors.accountName? "form-control is-invalid" : "form-control"} 
                                     id="accountName" 
                                     { ...register("accountName")}
                                     required
                                     placeholder="Enter Account Name" />
                              <p className="errors">{errors.accountName?.message}</p>
                            </div>
                          </div>


                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="accountNumber" className="form-label">Account Number*</label>
                              <input type="text" 
                                     className={ errors.accountNumber? "form-control is-invalid" : "form-control"} 
                                     id="accountNumber" 
                                     { ...register('accountNumber')}
                                     required
                                     placeholder="Enter Account Number" />


                            <p className="errors">{errors.accountNumber?.message}</p>
                            </div>
                          </div>


                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="accountType" className="form-label">Account Type*</label>
                              <input type="text" 
                                     className={ errors.accountType? "form-control is-invalid" : "form-control"} 
                                     id="accountType"
                                     { ...register('accountType')} 
                                     required
                                     placeholder="Enter Account Type" />

                              <p className="errors">{errors.accountType?.message}</p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label for="accountType" className="form-label">Swift Code*</label>
                              <input type="text" 
                                     className={ errors.swiftCode? "form-control is-invalid" : "form-control"} 
                                     id="accountType"
                                     { ...register('swiftCode')} 
                                     required
                                     placeholder="Enter Swift Code" />

                              <p className="errors">{errors.swiftCode?.message}</p>
                            </div>
                          </div>


                        </div>
                        {/* end of form row */}

                        <div className="row my-4">
                          <div className="col"></div>
                          <div className="col-auto">
                            { loading ? (
                              <button className="btn btn-primary">
                                <Loader
                                  type="TailSpin" 
                                  color="#ffffff"
                                  height={20} 
                                  width={20} />
                              </button>
                            ) : (
                              <button className="btn btn-primary" type="submit">Submit</button>
                            )}
                            {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
                
              </div>
            </div>
          </div>

        </div>

        </div> 


      </div>
    </div>
  </DashboardWrapper>
  )
}

export default CreateCompany
