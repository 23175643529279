import React from 'react';
import {DateTime} from 'luxon';
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import {thousandSeparator} from "../common";

export const REPORTCOMPANYCOLUMNS = [
  {
    id: 0,
    header: 'S/NO',
    accessor: 'id',
    Cell: props => <div>{(props.row.index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}</div>
  },
  {
    id: 1,
    header: 'company Name',
    accessor: 'companyName',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 2,
    header: 'Start',
    accessor: 'startDate',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  }, 
  {
    id: 3,
    header: 'End',
    accessor: 'endDate',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 4,
    header: 'users',
    accessor: 'staffCount',
    Cell: props => <div className="text-capitalize">{props.value   }</div>
  },
  {
    id: 5,
    header: 'Annual Rate',
    accessor: 'price',
    Cell: props => <div className="text-capitalize">{props.value > 0 ? thousandSeparator((props.value)) : 0}</div>
  },
  {
    id: 6,
    header: 'Annual Revenue',
    accessor: 'totalPrice',
    Cell: ( props ) => (
      <div className="text-capitalize">
        {props.value > 0 ? thousandSeparator( ( parseFloat( props.value ) ).toFixed( 2 ) ) : 0}
      </div>
    ),
  },
  {
    id: 7,
    header: 'MCL Annual Commission',
    accessor: 'commission',
    Cell: (props) => (
      <div className="text-capitalize">
        {props.value > 0 ? thousandSeparator((parseFloat(props.value)).toFixed(2)) : 0}
      </div>
    ),
  },

]
