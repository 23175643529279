import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL_PROMOTIONS || "http://api.rifaly.co.tz/api/";

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});



export const createPromotionApi = (data)=>{
  return api({
    url: `promotions`,
    data: data,
    method: "POST",
  });
}

export const fetchPromotionsApi = ()=>{
  return api({
    url: `promotions`,
    method: "GET",
  });
}

