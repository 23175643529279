import { fetchPublisherApi, fetchPublisherByIdApi } from "../../api/company";

export const FETCH_COMPANY_LOADING = "FETCH_COMPANY_LOADING";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_DETAILS_LOADING = "FETCH_COMPANY_DETAILS_LOADING";
export const FETCH_COMPANY_DETAILS_SUCCESS = "FETCH_COMPANY_DETAILS_SUCCESS";



export const fetchCompany = (id) => {
  return (dispatch) => {
    dispatch(fetchCompanyLoading());
    return fetchPublisherByIdApi(id)
      .then((res) => {
        console.log("data: ",res.data.data)
        const data = (res.data.data) || {};
        dispatch(fetchCompanySuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


const fetchCompanyLoading = () => {
  return {
    type: FETCH_COMPANY_LOADING,
  };
};


const fetchCompanySuccess = (data) => {
  return {
    type: FETCH_COMPANY_SUCCESS,
    payload: data,
  };
};



export const fetchCompanyDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchCompanyDetailsLoading());
    return fetchPublisherByIdApi(id)
      .then((res) => {
        console.log(res)
        const data = (res.data.data) || [];
        console.log(data)
        dispatch(fetchCompanyDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const fetchCompanyDetailsLoading = () => {
  return {
    type: FETCH_COMPANY_DETAILS_LOADING,
  };
};

const fetchCompanyDetailsSuccess = (data) => {
  return {
    type: FETCH_COMPANY_DETAILS_SUCCESS,
    payload: data,
  };
};