import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const SubMenus = ({ item }) => {
const [subnav, setSubnav] = useState(false);

const showSubnav = () => setSubnav(!subnav);

return (
	<>
        <li className="nav-item">
          <NavLink to={item.path} onClick={item.subNav && showSubnav} className="nav-link">
              <i className="fe">
                  {item.icon}
              </i>
              <span>{item.title}</span>
              <div>
              {item.subNav && subnav
                  ? item.iconOpened
                  : item.subNav
                  ? item.iconClosed
                  : null}
              </div>
          </NavLink>
          {subnav &&
              item.subNav.map((item, index) => {
              return (
                  <NavLink to={item.path} key={index} className="nav-link" style={{paddingLeft:'3.5rem'}}>
                      <i className="fe">
                          {item.icon}
                      </i>
                      <span>{item.title}</span>
                  </NavLink>
              );
              })}
        </li>
	</>
);
};

export default SubMenus;
