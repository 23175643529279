import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { updateStaffsApi } from "../../../../api/user";
import { toast } from "react-toastify";

function StaffEdit(props) {
  // const data = staff
  var user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var staff = props.data;
  staff.first_name = staff.fullName.split(" ")[0];
  staff.last_name = staff.fullName.split(" ")[1];
  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = { id: staff.id };
    // data.companyId = staff.companyId;
    // data.id = staff.id;
    // data.fullName = data.firstName + " " + data.lastName;
    // console.log(data);
    let options = { authorization: user.token };
    let form = document.getElementById("editForm");
    let inputs = Array.from(form.elements);
    data.fullName = inputs[0].value.trim() + " " + inputs[1].value.trim();
    data.email = inputs[2].value.trim();
    data.phone = inputs[3].value.trim();
    data.role = inputs[4].value;
    if (inputs[5].value && inputs[5].value.length > 0)
      data.password = inputs[5].value;

    console.log("🚀 ~ file: StaffEdit.js:36 ~ handleSubmit ~ options", data);
    updateStaffsApi(data, options)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleClose();
        history("/staffs")
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.respose) {
          toast.error(error.response.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).finally(() => {
        setLoading(false)
      })
  };

  return (
    <div>
      <div className="me-2 text-success" onClick={handleShow}>
        Edit
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <div className="">
            <h6 className="header-pretitle">Edit</h6>

            <h1 className="header-title">Edit Staff {staff.last_name}</h1>
          </div>

          <hr />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form id="editForm" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className={"form-control"}
                    id="firstName"
                    placeholder="Enter First name"
                    autoFocus
                    defaultValue={staff.first_name}
                    required
                    name="firstName"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className={"form-control"}
                    id="lastName"
                    placeholder="Enter Last name"
                    defaultValue={staff.last_name}
                    required
                    name="lastName"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter Email Address"
                    defaultValue={staff.email}
                    required
                    name="email"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Enter Phone Number"
                    required
                    defaultValue={staff.phone}
                    name="phone"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="role" className="form-label">
                    Role
                  </label>
                  <select
                    className="form-control"
                    name="role"
                    id="role"
                    defaultValue={staff.role}>
                    <option value="7">Finance</option>
                    <option value="6">Uploader</option>
                    <option value="2">Adminstraror</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="role" className="form-label">
                    Password
                  </label>
                  <input
                    defaultValue={staff.password}
                    type={"password"}
                    className="form-control"
                    id="password"
                    placeholder="Enter new password"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {loading ? (
                  <button className="btn btn-primary ml-2">
                    <Loader
                      type="TailSpin"
                      color="#ffffff"
                      height={20}
                      width={20}
                    />
                  </button>
                ) : (
                  <Button
                    className="btn btn-primary ml-2"
                    type="submit"
                    onClick={handleSubmit}>
                    Save
                  </Button>
                )}
                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
              </div>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default StaffEdit;
