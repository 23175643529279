import React, { useState } from 'react';
import { createColumnHelper } from "@tanstack/react-table";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import SharePopup from "./SharePopup";

function checkStatus(status) {
  if (status && status.toUpperCase() == "PUBLISHED") {
    return (
      <div className="status">
        <div className="dot"></div>
        Published
      </div>
    );
  } else {
    return (
      <div className="status">
        <div className="dot inactive"></div>
        {status}
      </div>
    );
  }
}
const formatDate = (date) => {
  if (date) return DateTime.fromMillis(date).toLocaleString(DateTime.DATE_MED);
  else return date;
};
const columnHelper = createColumnHelper();
export const columns = [
  columnHelper.accessor((row) => row.issue.title, {
    cell: (info) => info.getValue(),
    header: "Title",
  }),
  columnHelper.accessor((row) => row.publisher.companyName, {
    cell: (row) => row.getValue(),
    header: "Publisher",
  }),

  columnHelper.accessor("issueNo", {
    cell: (row) => row.getValue(),
    header: "Issue No",
  }),
  columnHelper.accessor("publication_timestamp", {
    cell: (row) => {
      return formatDate(row.getValue());
    },
    header: "Date Published",
  }),
  columnHelper.accessor("status", {
    cell: (row) => {
      return checkStatus(row.getValue());
    },
    header: "Status",
  }),
  columnHelper.accessor("id", {
    cell: (info) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
        <SharePopup data={info.row.original} />
          <Dropdown>
            <Dropdown.Toggle
              as="a"
              className="dropdown-ellipses icon-dropdown"
              id="dropdown-basic"
            >
              <i className="fe fe-more-horizontal"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-end">
              {/* <Dropdown.Item href="#/action-1"> */}
              <Link
                to={`/publications/${info.getValue()}`}
                className="dropdown-item"
              >
                View
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    },
    header: "Action",
  }),
];
