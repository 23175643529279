import React, { useCallback,useState } from "react";
import Dropzone from "react-dropzone";
import Loader from "react-loader-spinner";
import { uploadPublisherDocsApi } from "../../../../api/company";


const EditDocs = (props)=>{
var company = props.company;
var body = {
    companyId:company.id
}
const [error,setError] = useState("")
const [hasFile, setHasFile] = useState(false);
const [blFileInfo, setBlFileInfo] = useState({});
const [niFileInfo, setNiFileInfo] = useState({});
const [pcFileInfo, setPcFileInfo] = useState({});
const [ciFileInfo, setCiFileInfo] = useState({});
const [tinFileInfo, setTinFileInfo] = useState({});
const [reqBody,setReqBody] = useState(body);
const [isLoading,setIsLoading] = useState(false)

function readFile(file, type){
    if(file){
        var reader = new FileReader();
        reader.onabort = () => setError('File upload aborted')
        reader.onerror = () => setError('File upload failed')
        reader.addEventListener('load',()=>{
            if(reader.readyState == 2 && reader.result != null){
                switch(type){
                    case "bl":
                        body.businessLicense = reader.result;
                        break;
                    case "pc":
                        body.publishingCertificate = reader.result;
                        break;
                    case "ic":
                        body.incorporationCertificate = reader.result;
                        break;
                    case "ni":
                        body.nationalIdLetter = reader.result;
                        break;
                    case "tin":
                        body.tin = reader.result;
                        break;
                        default:
                            break;
                }
                setHasFile(true);
                setReqBody(body)
            }
        },false);

        reader.readAsDataURL(file);
    }
  
  }

  const onDropTINFile=useCallback((uploadedFiles)=>{
    readFile(uploadedFiles[0],"tin");
    setTinFileInfo({
        'lastModified'     : uploadedFiles[0].lastModified,
        'lastModifiedDate' : uploadedFiles[0].lastModifiedDate,
        'name'             : uploadedFiles[0].name,
        'size'             : uploadedFiles[0].size,
        'type'             : uploadedFiles[0].type
    });
    
},[]);
const onDropBLFile=useCallback((uploadedFiles)=>{
    readFile(uploadedFiles[0],"bl");
    setBlFileInfo({
        'lastModified'     : uploadedFiles[0].lastModified,
        'lastModifiedDate' : uploadedFiles[0].lastModifiedDate,
        'name'             : uploadedFiles[0].name,
        'size'             : uploadedFiles[0].size,
        'type'             : uploadedFiles[0].type
    });

},[]);

const onDropPCFile=useCallback((uploadedFiles)=>{
    readFile(uploadedFiles[0],"pc");
    setPcFileInfo({
        'lastModified'     : uploadedFiles[0].lastModified,
        'lastModifiedDate' : uploadedFiles[0].lastModifiedDate,
        'name'             : uploadedFiles[0].name,
        'size'             : uploadedFiles[0].size,
        'type'             : uploadedFiles[0].type
    });

    console.log("file pc: ",pcFileInfo);
},[]);

const onDropNIFile=useCallback((uploadedFiles)=>{
    readFile(uploadedFiles[0],"ni");
    setNiFileInfo({
        'lastModified'     : uploadedFiles[0].lastModified,
        'lastModifiedDate' : uploadedFiles[0].lastModifiedDate,
        'name'             : uploadedFiles[0].name,
        'size'             : uploadedFiles[0].size,
        'type'             : uploadedFiles[0].type
    });

    console.log("file pc: ",niFileInfo);
},[]);

const onDropCIFile=useCallback((uploadedFiles)=>{
    readFile(uploadedFiles[0],"ic");
    setCiFileInfo({
        'lastModified'     : uploadedFiles[0].lastModified,
        'lastModifiedDate' : uploadedFiles[0].lastModifiedDate,
        'name'             : uploadedFiles[0].name,
        'size'             : uploadedFiles[0].size,
        'type'             : uploadedFiles[0].type
    });

    console.log("file pc: ",ciFileInfo);
},[]);

const handleSave=(e)=>{
    setIsLoading(true)
    console.log("reqbody: ",reqBody);
    uploadPublisherDocsApi(reqBody).then(result=>{
        console.log("resultx: ",result.data);
    }).catch(e=>{
        setError("Something went wrong!")
    })
    .finally(()=>{
        setIsLoading(false);
    })
}

return(
<section className='detail-section mt-4'>
    <div className="detail-title">
        <div className="row">
        <div className="col">
            <h3>Business Infomation</h3>
        </div>
    </div>
    </div>

    <div className="detail-contents">
        

        <div className="col-md-12 mt-4">
            
            <div className="dc-body">
            <div className='dc-body_file'>
                
                <Dropzone onDrop={onDropTINFile} multiple={false} accept=".pdf">
              {({getRootProps, getInputProps}) => (
                <section>
                  <div className="mb-1">
                    <label className="form-label">Upload TIN Certificate</label>  
                  </div>
                  <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                    <input {...getInputProps()} />
                    <div className="upload-content p-4">
                      <i className="fe fe-upload-cloud"></i>
                      <p>Drag and drop or click to upload PDF only</p>
                      <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                    </div>
                  </div>
                </section>
              )}
              </Dropzone>
            </div>
            {tinFileInfo.name ? "File: "+tinFileInfo.name:""}
            </div>

            <hr className='no-pad' />

        </div>

        <div className="col-md-12 mt-4">
            
            <div className="dc-body">
            <div className='dc-body_file'>
                
                <Dropzone onDrop={onDropBLFile} multiple={false} accept=".pdf">
              {({getRootProps, getInputProps}) => (
                <section>
                  <div className="mb-1">
                    <label className="form-label">Upload Business License</label>  
                  </div>
                  <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                    <input {...getInputProps()} />
                    <div className="upload-content p-4">
                      <i className="fe fe-upload-cloud"></i>
                      <p>Drag and drop or click to upload PDF only</p>
                      <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                    </div>
                  </div>
                </section>
              )}
              </Dropzone>
            </div>
            {blFileInfo.name ? "File: "+blFileInfo.name:""}
            </div>

            <hr className='no-pad' />

        </div>
        <div className="col-md-12 mt-4">
        <div className="dc-body">
            <div className='dc-body_file'>
                
                <Dropzone onDrop={onDropPCFile} multiple={false} accept=".pdf">
              {({getRootProps, getInputProps}) => (
                <section>
                  <div className="mb-1">
                    <label className="form-label">Upload Publishing Certificate</label>  
                  </div>
                  <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                    <input {...getInputProps()} />
                    <div className="upload-content p-4">
                      <i className="fe fe-upload-cloud"></i>
                      <p>Drag and drop or click to upload PDF only</p>
                      <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                    </div>
                  </div>
                </section>
              )}
              </Dropzone>
            </div>
            {pcFileInfo.name ? "File: "+pcFileInfo.name:""}
            </div>
            <hr className='no-pad' />

        </div>

        <div className="col-md-12 mt-4">
        <div className="dc-body">
            <div className='dc-body_file'>
                
                <Dropzone onDrop={onDropCIFile} multiple={false} accept=".pdf">
              {({getRootProps, getInputProps}) => (
                <section>
                  <div className="mb-1">
                    <label className="form-label">Upload Certificate of Incorporation</label>  
                  </div>
                  <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                    <input {...getInputProps()} />
                    <div className="upload-content p-4">
                      <i className="fe fe-upload-cloud"></i>
                      <p>Drag and drop or click to upload PDF only</p>
                      <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                    </div>
                  </div>
                </section>
              )}
              </Dropzone>
            </div>
            {ciFileInfo.name ? "File: "+ciFileInfo.name:""}
            </div>
            <hr className='no-pad' />

        </div>

        <div className="col-md-12 mt-4">
        <div className="dc-body">
            <div className='dc-body_file'>
                
                <Dropzone onDrop={onDropNIFile} multiple={false} accept=".pdf">
              {({getRootProps, getInputProps}) => (
                <section>
                  <div className="mb-1">
                    <label className="form-label">Upload National ID Letter</label>  
                  </div>
                  <div {...getRootProps({className: 'dropzone dropzone-single mb-3'})}>
                    <input {...getInputProps()} />
                    <div className="upload-content p-4">
                      <i className="fe fe-upload-cloud"></i>
                      <p>Drag and drop or click to upload PDF only</p>
                      <p className="btn btn-sm btn-outline-primary">Browse from your files</p>
                    </div>
                  </div>
                </section>
              )}
              </Dropzone>
            </div>
            {niFileInfo.name ? "File: "+niFileInfo.name:""}
            </div>
            <hr className='no-pad' />

        </div>

       {hasFile ? (isLoading ? <span className="btn btn-primary">
                  <Loader
                    type="TailSpin" 
                    color="#ffffff"
                    height={20} 
                    width={20} />
                </span>:<button className="btn btn-primary" onClick={handleSave}>SAVE</button>):null}
    </div>
</section>
)
}
export default EditDocs;