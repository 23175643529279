import { fetchPublicationsApi, fetchPublicationDetailsApi } from "../../api/publisher";

export const FETCH_PUBLICATIONS_SUCCESS = "FETCH_PUBLICATIONS_SUCCESS";
export const FETCH_PUBLICATIONS_LOADING = "FETCH_PUBLICATIONS_LOADING";
export const FETCH_PUBLICATION_DETAILS_SUCCESS = "FETCH_PUBLICATION_DETAILS_SUCCESS";
export const FETCH_PUBLICATION_DETAILS_LOADING = "FETCH_PUBLICATION_DETAILS_LOADING";



export const fetchPublications = (publisherId, page) => {
  return (dispatch) => {
    dispatch(fetchPublicationsLoading());
    return fetchPublicationsApi(publisherId, page)
      .then((res) => {
        const data = (res.data.data) || [];
        dispatch(fetchPublicationsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchPublicationDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchPublicationDetailsLoading());
    return fetchPublicationDetailsApi(id)
      .then((res) => {
        const data = (res.data.data) || [];
        dispatch(fetchPublicationDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


const fetchPublicationsLoading = () => {
  return {
    type: FETCH_PUBLICATIONS_LOADING,
  };
};


const fetchPublicationsSuccess = (data) => {
  return {
    type: FETCH_PUBLICATIONS_SUCCESS,
    payload: data,
  };
};

const fetchPublicationDetailsLoading = () => {
  return {
    type: FETCH_PUBLICATION_DETAILS_LOADING,
  };
};


const fetchPublicationDetailsSuccess = (data) => {
  return {
    type: FETCH_PUBLICATION_DETAILS_SUCCESS,
    payload: data,
  };
};