import React from "react";
import { ReactComponent as RifalyLogo } from "../../assets/rifaly.svg";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import coverImage from '../../assets/img/covers/cover.png';
import './style.css';

export default function HomeWrapper({children}) {

  const backgroundImageStyle = {
    backgroundImage: `linear-gradient(0deg, #0f1f26f2 0%, #0f1f26f2 100%), url(${coverImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  };

  return (
    <div className="d-flex align-items-center bg-auth">
      <div className="container-fluid px-0">
        <div className="row justify-content-center">

          <div className="col-12 col-md-7 col-lg-6 col-xl-6 d-none d-lg-block  p-0" style={backgroundImageStyle}>
            <div className="side-bg h-100 min-vh-100">
              <div className="logo-holder">
                <RifalyLogo />
                <p className="mt-4">Rifaly provides an easy way to read high-quality newspaper and magazine issues from cover to cover on the go!</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-5 col-lg-6 col-xl-6 px-lg-6 my-5 align-self-center">
            <div className="inner-content">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={3}
              />
              {children}
            </div>
          </div>


        </div>
      </div>
    </div >
  )
}