import React from "react";
import {thousandSeparator} from "../common";

export const REPORTCOLUMNS = [
  {
    id: 0,
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    id: 1,
    header: "paper name",
    accessor: "title",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    id: 2,
    header: "Currency",
    accessor: "currency",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    id: 3,
    header: "Downloads",
    accessor: "downloadCount",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    id: 4,
    header: "Publisher Revenue (TZS)",
    accessor: "amount",
    Cell: (props) => (
      <div className="text-capitalize">
        {props.value > 0 ? thousandSeparator((parseFloat(props.value)).toFixed(0)) : 0}
      </div>
    ),
  },
];
