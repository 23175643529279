import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import  DashboardWrapper  from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import Navs from "./StaffNavs";
import StaffTable from './StaffTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStaffs } from '../../../../redux-store/Actions/staff-action';



export default function Staff() {
    const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));

  const history = useNavigate();
  const dispatch = useDispatch();
  function toDashboard(e) {
    e.preventDefault();
    history("/dashboard");
  }

  // const {staffs} = useSelector((state)=>state.staffs)

  // useEffect(()=>{
  //   dispatch(fetchStaffs(user.companyId));

  // },[dispatch])

  return (
    <DashboardWrapper>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">
           
            <div className="header mt-md-3">

              <nav aria-label="breadcrumb mb-2">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toDashboard} >Dashboard</a>
                        
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">Staffs</li>
                    </ol>
              </nav>

              <div className="header-body">

      
                <h6 className="header-pretitle">
                  Overview
                </h6>

                
                <h1 className="header-title">
                  Staffs
                </h1>

              </div>
            </div>


            <div className="content">
              <Navs></Navs>

              <div className="row align-items-end my-4">
                <div className="col">

               
                  {/* <form action="" className="form d-flex">
                    <input type="text" className="form-control" />

                    <input type="text" className="form-control" />
                  </form> */}

                </div>

                <div className="col-auto">

                  {user.role == 2?
                  <Link to="/staffs/create" className="btn btn-primary lift">
                    <span>
                      <i className="fe fe-plus"></i>
                    </span>
                    Create Staff
                  </Link>
                  :null}
                </div>
              </div> 

              <div className="row mt-2 body-content">
                <div className="col-md-12">
                
                  <StaffTable />
                </div>
              </div>


            </div>

            </div>


          </div>
        </div>
      </DashboardWrapper>
  )
}
