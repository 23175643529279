import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import HomeWrapper from '../../components/HomeWrapper';
import Loader from 'react-loader-spinner';
import { resetPasswordApi } from '../../api/auth';
import { toast, Slide } from 'react-toastify';


const schema = yup.object({
    login_id: yup.string().required().label("Missing login ID"),
    token: yup.string().required().max(8).label("Verification code"),
    password: yup.string().required().min(6).label("Password too short"),
    cpassword: yup.string().oneOf([yup.ref("password")],"Passwords must match")
}).required()


export default function NewPassword () { 
    const location = useLocation();
    const state = location.state;

  const navigate = useNavigate();
  const clearWaitingQueue = () => {
    // Easy, right 😎
    toast.clearWaitingQueue();
  }



  const { register, 
    handleSubmit,
    setError,
    formState:{ errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = data => {
    console.log("d:ne ",data);
    setLoading(true)
    // post data to API
    resetPasswordApi(data).then((response) => {
      console.log(response.data);
      let user = response.data.data;
      localStorage.setItem("RIFALYPUBLISHER", JSON.stringify(user.data));
      navigate('/overview');

    }).catch(error => {
      console.log(error)
      setLoading(false);

      if (error.response) {
        console.log(error.response.status)

        if(parseInt(error.response.status) === 401){

          setError("login_id", {
            type: "manual",
            message: "Invalid login ID and password Combination!",
          });

          console.log("User not found")
          toast.error(<div className='small'> Unauthorised ! <br /> Invalid Credentials. </div>, {
            position: "top-right",
            autoClose: 5000,
            transition: Slide,
            theme: "colored",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            draggable: true
          });
          clearWaitingQueue()
        }

        if (error.response.status === 422){

          setError("login_id", {
            type: "manual",
            message: "Invalid Email Address",
          });

          console.log(error.response)
        }



        if(error.response.status === 500){
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }

        
      }  else {
        console.log(error.message);
      }


    });
    // get response
    // if successfull store token in localstorage
    // redirect user to login

    console.log(data)

  };


    return (
      <HomeWrapper>
       <h3 className="text-black mb-3">
            Forgot Password ?
        </h3>
        <p className="text-muted mb-5">
          Create a new password now <br />
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
            <input type='hidden' {...register("token")} value={state.token}/>
            <input type="hidden" {...register("login_id")} value={state.login_id} />
            <label className='errors'>{errors.login_id?.message}</label>
          <div className="form-group">
            <label htmlFor="password" className="form-label">
              Type New Password
            </label>
            <input type="password" 
                   className={ errors.password ? "form-control is-invalid" : "form-control"} 
                   autoFocus 
                   placeholder="****"
                   {...register("password")} />

            <p className="errors">{errors.password?.message}</p>

          </div>
          <div className="form-group">
            <label htmlFor="password" className="form-label">
              Re-type Password
            </label>
            <input type="password" 
                   className={ errors.passsword ? "form-control is-invalid" : "form-control"} 
                   autoFocus 
                   placeholder="****"
                   {...register("cpassword")} />

            <p className="errors">{errors.cpassword?.message}</p>

          </div>

          <div className="form-group row">
            <div className="col-md-12">
              { loading ? (
                <span className="btn btn-primary">
                  <Loader
                    type="TailSpin" 
                    color="#ffffff"
                    height={20} 
                    width={20} />
                </span>
              ) : (
                <input className="btn btn-primary w-100" type="submit" value="Reset Password" />
              )}
            </div>


          </div>


          

          
        </form>

    
      </HomeWrapper>
    )

}

