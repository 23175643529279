import React from 'react'
import {Link} from 'react-router-dom'
import {ReactComponent as Empty} from '../../assets/empty.svg'

function EmptyData(props) {
  return (
    <div className="page-empty">
      <div className="card card-fill">
        <div className="card-body text-center">

          <Empty />

          <h3 className="mt-4">No {props.name}</h3>

          <p className="text-muted m-4">

          </p>
          {props.name.includes("Reports") ? null :
            <Link to={props.path} className="btn btn-primary lift">
              <span>
                <i className="fe fe-plus"></i>
              </span>
              Create {props.name}
            </Link>
          }
        </div>
      </div>
    </div>
  )
}

export default EmptyData
