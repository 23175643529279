import axios from "axios";
import api_url from "./api_config";

const baseURL = api_url.PUBLISHER_URL;
const countryApi = axios.create({
  baseURL: api_url.COUNTRIES_URL,
  headers: {"Content-type": "application/json"},
});
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchStaffsApi = (id) => {
  return api({
    url: `staffs`,
    method: "GET",
  });
};

export const fetchStaffDetailsApi = (id) => {
  return api({
    url: `staffs/${id}`,
    method: "GET",
  });
};

export const createStaffsApi = (data) => {
  return api({
    url: `staffs`,
    data: data,
    method: "POST",
  });
};

export const updateStaffsApi = (data) => {
  return api({
    url: `staffs/update/`,
    data: data,
    method: "POST",
  });
};

// issues
export const fetchIssuesApi = (publisherId) => {
  return api({
    url: `issues/${publisherId}`,
    method: "GET",
  });
};

export const fetchIssueDetailsApi = (id) => {
  return api({
    url: `issues/show/${id}`,
    method: "GET",
  });
};

// issues - get Companies
export const fetchCompanyIssuesApi = (publisher, category) => {
  return api({
    url: `issues/available/?publisher=${publisher}&category=${category}`,
    method: "GET",
  });
};

// publishers - get issue categories
export const fetchCompanyIssueCategoriesApi = (id) => {
  return api({
    url: `issues/categories/?publisher=${id}`,
    method: "GET",
  });
};

// update issue - Activate / deactivate
export const updateIssueStatusApi = (id, data) => {
  return api({
    url: `issues/${id}`,
    data: data,
    method: "PATCH",
  });
};

/* 

  ISSUE APIS
  manage and fetch issues APIs
*/
export const createIssuesApi = (data) => {
  return api({
    url: `issues`,
    data: data,
    method: "POST",
  });
};

export const updateIssuesApi = (data) => {
  return api({
    url: `issues/update/`,
    data: data,
    method: "POST",
  });
};

export const deleteIssuesApi = (id) => {
  return api({
    url: `issues/`,
    data: id,
    method: "DELETE",
  });
};

// fetch genres
export const fetchGenresApi = () => {
  return api({
    url: `genres/list`,
    method: "GET",
  });
};

/* 
  stories API
*/
export const fetchStoriesApi = (channelId, currentPage) => {
  return api({
    url: `audio/v3/all/${channelId}/?page=${currentPage}`,
    method: "GET",
  });
};

export const fetchStoryDetailsApi = (id) => {
  return api({
    url: `audio/v3/show/${id}`,
    method: "GET",
  });
};

export const createStoriesApi = (data) => {
  return api({
    url: `audio/v3`,
    data: data,
    method: "POST",
  });
};
export const createStoriesViaRSSApi = (data) => {
  return api({
    url: `audio/v3/rss-feed`,
    data: data,
    method: "POST",
  });
};
export const createStoriesSeasonsApi = (data) => {
  return api({
    url: `audio/v3`,
    data: data,
    method: "POST",
  });
};
export const fetchSeasonsDetailsApi = (id) => {
  return api({
    url: `audio/v3/collections/${id}`,
    method: "GET",
  });
};

export const deleteStoriesApi = (id) => {
  return api({
    url: `audio/v3/`,
    data: id,
    method: "DELETE",
  });
};

export const updateStoryStatusApi = (id, data) => {
  return api({
    url: `audio/v3/${id}`,
    data: data,
    method: "PATCH",
  });
};

export const updateStoryApi = (data) => {
  return api({
    url: `audio/v3/update`,
    data: data,
    method: "POST",
  });
};
export const formatDuration = (seconds) => {
  let hours = Number(seconds / 3600).toFixed(0);
  let minutes = Number((seconds % 3600) / 60).toFixed(0);
  let secs = Number(seconds - minutes * 60 - hours * 3600).toFixed(0);
  return `${hours}:${minutes}:${secs}`;
};

// publications - changed hia
export const fetchPublicationsApi = (publisherId, currentPage) => {
  return api({
    url: `publications/v2/list/${publisherId}/?page=${currentPage}`,
    method: "GET",
  });
};

export const fetchPublicationDetailsApi = (id) => {
  return api({
    url: `publications/v2/view/details/${id}`,
    method: "GET",
  });
};

export const createPublicationsApi = (data, config) => {
  return api(
    {
      url: `publications/v2`,
      data: data,
      method: "POST",
    },
    config,
  );
};

export const createPublicationsApiTest = (data) => {
  return api({
    url: `publications/v2/test`,
    data: data,
    method: "POST",
  });
};
export const updatePublicationsApi = (data, config) => {
  return api({
    url: `publications/v2/update/${data.id}`,
    data: data,
    method: "PUT",
    headers: config.headers,
  });
};

export const publishPublicationApi = (options) => {
  return api({
    url: `publications/v2/publish/${options.id}`,
    method: "PATCH", data: {status: options.status},
    headers: {Authorization: `Bearer ${options.token}`},
  });
};

export const fetchBooksApi = () => {
  return api({
    url: `publications/v2/books`,
    method: "GET",
  });
};

export const fetchBooksDetailsApi = (id) => {
  return api({
    url: `publications/v2/books/${id}`,
    method: "GET",
  });
};

//FETCH COUNTRIES
export const fetchCountriesApi = () => {
  return countryApi({url: `supported`, method: "GET"});
};
