import React from 'react';
import {DateTime} from 'luxon';

export const REPORTCOLUMNS = [
  {
    id: 0,
    header: 'S/NO',
    accessor: 'id',
    Cell: props => <div>{(props.row.index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}</div>
  },
  {
    id: 1,
    header: 'Company',
    accessor: 'companyName',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 2,
    header: 'Download Count',
    accessor: 'downloads',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  }
]
