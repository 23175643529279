import WebViewer from "@pdftron/webviewer";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfWebViewer = (props) => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [show,setShow] = useState(props.show);
  const onLoadSuccess = (pages) => {
    console.log("complete");
    setTotalPages(pages);
    setCurrentPage(1);
  };
 
  return (
    <div className="modal show">
      <Modal show={show} onHide={() => setShow(false)} role="dialog" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Publication Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Document file={props.link} onLoadSuccess={onLoadSuccess}>
            <Page className="viewer" scale="0.5" pageNumber={currentPage} />
          </Document>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={()=>setShow(false)}>Looks Good</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default PdfWebViewer;
