import { fetchCorporatesApi, fetchCorporateByIdApi } from "../../api/corporate";

export const FETCH_CORPORATES_LOADING = "FETCH_CORPORATES_LOADING";
export const FETCH_CORPORATES_SUCCESS = "FETCH_CORPORATES_SUCCESS";
export const FETCH_CORPORATE_DETAILS_LOADING = "FETCH_COMPANY_DETAILS_LOADING";
export const FETCH_CORPORATE_DETAILS_SUCCESS = "FETCH_COMPANY_DETAILS_SUCCESS";



export const fetchCorporate = () => {
  return (dispatch) => {
    dispatch(fetchCorporatesLoading());
    return fetchCorporatesApi()
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchCorporatesSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


const fetchCorporatesLoading = () => {
  return {
    type: FETCH_CORPORATES_LOADING,
  };
};


const fetchCorporatesSuccess = (data) => {
  return {
    type: FETCH_CORPORATES_SUCCESS,
    payload: data,
  };
};



export const fetchCorporateDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchCorporateDetailsLoading());
    return fetchCorporateByIdApi(id)
      .then((res) => {
        console.log(res)
        const data = (res.data.data) || [];
        console.log(data)
        dispatch(fetchCorporateDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const fetchCorporateDetailsLoading = () => {
  return {
    type: FETCH_CORPORATE_DETAILS_LOADING,
  };
};

const fetchCorporateDetailsSuccess = (data) => {
  return {
    type: FETCH_CORPORATE_DETAILS_SUCCESS,
    payload: data,
  };
};