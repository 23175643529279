

export const thousandSeparator =(x)=> {
    return typeof(x) == "string" || !isNaN(x) ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): x;
}

// //convert currency to tsh
// export const toTZS=(amount,currency)=>{
  
//    if(oxrReady){
//     money.rates = oxr.rates;
//     money.base = oxr.base;
//     console.log("converting now... ");
//     return money(amount).from(currency).to('TZS');
//    }
//    else {console.log("xx");
//    return amount;
// }
// }