import React, { useEffect, useState, useCallback, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import {
  fetchCompany,
  fetchCompanyDetails,
} from "../../../../redux-store/Actions/company-action";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanyIssueCategoriesApi,
  fetchCompanyIssuesApi,
} from "../../../../api/publisher";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import reactPdfThumbnail from 'react-pdf-thumbnail';
import WebViewer from "@pdftron/webviewer";
import { Document, Page, pdfjs } from "react-pdf";
import PdfWebViewer from "./PdfWebViewer";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import api_config from "../../../../api/api_config";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const schema = yup
  .object({
    // publisherId: yup.integer(),required().label("Promotion Title")
  })
  .required();

export default function CreatePublication() {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.company);
  const data = company;
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [loading, setLoading] = useState(false);
  const [publicationFile, setPublicationFile] = useState({});
  const [selectedIssue, setselectedIssue] = useState({
    categoryId: 1,
    id: 1,
    price: 0,
    status: "ACTIVE",
    title: "Paper",
  });
  const [loadedFile, setLoadedFile] = useState([]);
  const [categories, setCategories] = useState([]);
  const [issues, setIssues] = useState([]);
  const [publishDate, setPublishDate] = useState("");
  const [topStories, setTopStories] = useState(["first"]);
  const [showAddStoriesButton, setshowAddStoriesButton] = useState(true);
  const [show, setShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [progressValue, setProgressValue] = useState(0);

  const files = loadedFile.map((f) => <li key={f.name}>{f.name}</li>);

  const onDropPublicationFile = useCallback((uploadedFiles) => {
    readFiles(uploadedFiles);
    // console.log(loadedFile)
  }, []);

  function readFiles(files) {
    console.table(files);
    setLoadedFile(files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reader aborted");

      reader.onerror = () => console.log("file reader failed");

      reader.onload = () => {
        const dataUrl = reader.result;
        // console.log(dataUrl)
        setShow(true);
        setPublicationFile({ url: dataUrl });
      };

      // Read the file as a data url
      reader.readAsDataURL(file);
    });
  }

  const navigate = useNavigate();

  const createPublicationsApi = async (data) => {
    const config = {
      onUploadProgress: (progressEvent) => {
        const completed = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log("progress: ", completed);
        setProgressValue(completed);

        if (completed >= 100) {
          setLoading(false);
          showNotification();
        }
      },
    };
    let result = axios.post(
      `${api_config.PUBLISHER_URL}publications`,
      data,
      config
    );
    return result;
  };
  const showNotification = () => {
    toast.info(
      "Your publication is being processed; Kindly check the list after a short while.",
      {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    navigate("/publications");
  };
  const onSubmit = (data, e) => {
    setLoading(true);

    data.price = selectedIssue.price;
    data.publicationFile = publicationFile;
    data.releaseDate = Date.parse(publishDate.toISOString());
    data.publisherId = company.id;
    console.log("data: ", data);

    if (
      !data.publisherId ||
      !data.issueId ||
      !data.publicationFile.url ||
      !data.categoryId ||
      data.categoryId.includes("Select") ||
      data.issueId.includes("Select")
    ) {
      toast.error("Please provide all required information", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    } else {
      createPublicationsApi(data)
        .then((res) => {
          console.log("res: ", res.data);

          e.target.reset();
          setLoading(false);
          let message = "";
          if (res.data.status == 403 || res.data.status == 409) {
            message = res.data.error;
            toast.error(message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            message = "Publication Created Successfully";
            toast.success("Publication Created successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            navigate("/publications");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("err: ", error.response);
          let message = "Failed to create publication";
          if (error.response && error.response.status === 409)
            message = error.response.data.message;
          toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    let companyId = JSON.parse(
      localStorage.getItem("RIFALYPUBLISHER")
    ).companyId;
    console.log("id: ", companyId, data.id);
    dispatch(fetchCompanyDetails(companyId));
    fetchCompanyIssueCategoriesApi(companyId).then((result) => {
      console.log(result.data.data);
      const newCats = result.data.data;
      //set categories
      setCategories(newCats);
    });
  }, [dispatch]);

  useEffect(() => {
    topStories.length === 3
      ? setshowAddStoriesButton(false)
      : setshowAddStoriesButton(true);
  }, [topStories.length]);

  const findIssue = (issues, id) => {
    return issues.find((element) => {
      return element.id === parseInt(id);
    });
  };

  const addStory = () => {
    console.log(topStories.length);

    switch (topStories.length) {
      case 1:
        setTopStories([...topStories, "second"]);
        break;

      case 2:
        setTopStories([...topStories, "third"]);
        break;

      default:
        break;
    }
  };

  const handleCancelForm = (e) => {
    e.preventDefault();
    navigate("/publications");
  };

  const catChange = (e) => {
    console.log(e.target.value);
    const catId = e.target.value;

    setIssues([]);
    // fetch categories in the pubs
    fetchCompanyIssuesApi(data.id, catId).then((result) => {
      console.log(result.data.data);
      const newIssues = result.data.data;
      const activeIssues = newIssues.filter(
        (issue) => issue.status.toLowerCase() === "active"
      );
      //set data
      setIssues(activeIssues);
      setselectedIssue(activeIssues.length > 0 ? newIssues[0] : {});
    });
  };

  const issueChange = (e) => {
    var selected = e.target.value;
    const iss = findIssue(issues, selected);
    setselectedIssue(iss);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() - 24 * 3600 * 1000 <= selectedDate.getTime();
  };

  const handleChangeRaw = (value) => {
    // if (value === 'tomorrow') {
    console.log("value: ", value);
    // }
  };

  const CustomDatePicker = () => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="example-custom-input form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
        required
      />
    ));
    return (
      <DatePicker
        isClearable={true}
        selected={publishDate}
        dateFormat={"dd-MM-yyyy HH:mm"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime}
        showTimeSelect
        timeIntervals={30}
        onChange={(date) => {
          setPublishDate(date);
        }}
        onChangeRaw={(e) => {
          handleChangeRaw(e.target.value);
        }}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };
  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item"
                    aria-current="page"
                    onClick={toPage("/publications")}
                  >
                    Publications
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create Publication
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card pt-3">
                  <div className="card-heade px-4 pt-4">
                    <div className="">
                      <h6 className="header-pretitle">Overview</h6>

                      <h1 className="header-title">Upload Publication</h1>
                    </div>

                    <hr />
                  </div>

                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)} className="p-3">
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <Dropzone
                            onDrop={onDropPublicationFile}
                            multiple={false}
                            accept=".pdf"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="mb-1">
                                  <label className="form-label">Upload</label>
                                </div>
                                <div
                                  {...getRootProps({
                                    className: "dropzone dropzone-single mb-3",
                                  })}
                                >
                                  <input {...getInputProps()} id="file-drop" />
                                  <div className="upload-content p-4">
                                    <i className="fe fe-upload-cloud"></i>
                                    <p>Drag and drop or click to upload</p>
                                    <p className="btn btn-sm btn-outline-primary">
                                      Browse from your files
                                    </p>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>

                          <aside>
                            <h4>Files</h4>
                            <ul>{files}</ul>
                          </aside>

                          {loadedFile ? (
                            <div>{loadedFile.name}</div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div className="col-md-6">
                          {publicationFile.url ? (
                            <Document
                              file={publicationFile.url}
                              onLoadSuccess={() => setCurrentPage(1)}
                            >
                              <Page
                                className="viewer-mini"
                                scale="0.25"
                                pageNumber={currentPage}
                              />
                            </Document>
                          ) : null}
                        </div>
                      </div>
                      <div className="col">
                        <div className=" mb-3">
                          {publicationFile.url ? (
                            <PdfWebViewer
                              onHide={() => setShow(false)}
                              show={show}
                              link={publicationFile.url}
                              size={"small"}
                            />
                          ) : (
                            "No Preview"
                          )}
                        </div>
                      </div>
                      <div className="row g-3 mb-3">
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label htmlFor="company" className="form-label">
                              Publisher
                            </label>
                            <select
                              disabled
                              value={data.id}
                              className="form-select"
                              {...register("publisherId")}
                            >
                              {
                                <option key={company.id} value={company.id}>
                                  {company.companyName}{" "}
                                </option>
                              }
                            </select>

                            <p className="errors">{errors.company?.message}</p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            Category
                          </label>
                          <select
                            {...register("categoryId")}
                            onChange={catChange}
                            className="form-select"
                            defaultValue={
                              categories.length > 0
                                ? categories[0].categoryId
                                : null
                            }
                          >
                            <option>Select Category</option>
                            {categories.map((category) => (
                              <option
                                value={category.categoryId}
                                key={category.categoryId}
                              >
                                {" "}
                                {category.Name}
                              </option>
                            ))}
                            {/* <option value="1">NewsPapers</option>
                                <option value="2">Magazine</option>
                                <option value="3">Books</option> */}
                          </select>
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            Title
                          </label>
                          <select
                            {...register("issueId")}
                            defaultValue={selectedIssue.id}
                            onChange={issueChange}
                            className="form-select"
                          >
                            <option>Select Issue Title</option>
                            {issues.map((issue) => (
                              <option key={issue.id} value={issue.id}>
                                {" "}
                                {issue.title}{" "}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-12 col-md-4 mb-3 timer">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            Date and Time of Release
                          </label>
                          <CustomDatePicker
                            className="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            selected={publishDate}
                            minDate={new Date().getTime}
                            showTimeSelect
                            timeIntervals={15}
                            // onSelect={(date) => setPublishDate(date)}
                            filterDate={filterPassedDate}
                            filterTime={filterPassedTime}
                          />
                          {/* <input type="datetime-local" 
                                     min={date}
                                     className="form-control" 
                                     id="validationServer02" 
                                     placeholder="Issue Title" 
                                     required
                                     {...register('releaseDate')} /> */}
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            Price
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="validationServer02"
                            placeholder="Issue Price"
                            value={selectedIssue.price}
                            required
                            disabled
                            {...register("price")}
                          />
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            Issue No
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="validationServer02"
                            placeholder="Issue No"
                            required
                            {...register("issueNo")}
                          />
                        </div>

                        {topStories.map((topStory, i) => (
                          <div key={i} className="col-12 col-md-12">
                            <div className="mb-1">
                              <label
                                htmlFor="validationServer02"
                                className="form-label capitalize"
                              >
                                {topStory === "first" ? "" : topStory} Top Story
                              </label>
                              <textarea
                                type="text"
                                rows="5"
                                {...register(`${topStory}Story`)}
                                className="form-control"
                                id="validationServer02"
                                placeholder="Enter Top story Of the Newspaper"
                              ></textarea>
                            </div>
                          </div>
                        ))}

                        <div className="col-12 col-md-12">
                          <div className="">
                            {showAddStoriesButton && (
                              <span className="btn btn-link" onClick={addStory}>
                                + Add more{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          {loading ? (
                            <div className="progress-container">
                              <label>Progress... {progressValue}%</label>
                              <progress
                                value={progressValue}
                                max={100}
                                id="progress"
                              >
                                {progressValue}%
                              </progress>{" "}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-auto">
                          <div className="d-flex flex-column flex-md-row align-items-md-end">
                            {!loading && (
                              <button
                                onClick={handleCancelForm}
                                className="btn btn-outline-secondary mb-2 mb-md-0 me-md-2"
                              >
                                Cancel
                              </button>
                            )}

                            {loading ? (
                              <button className="btn btn-primary mb-2 mb-md-0">
                                <Loader
                                  type="TailSpin"
                                  color="#ffffff"
                                  height={20}
                                  width={20}
                                />
                              </button>
                            ) : (
                              <button className="btn btn-primary" type="submit">
                                Publish
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
