import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Navs() {
  return (
    <ul className="nav nav-tabs">

      <li className="nav-item">
        <NavLink to="/publications/papers" className="nav-link">Publications</NavLink>
      </li>


      <li className="nav-item">
        <NavLink to="/publications/issues" className="nav-link">Issues</NavLink>
      </li>

    </ul>
  )
}
