import React, { useState } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import HomeWrapper from '../../components/HomeWrapper';
import Loader from 'react-loader-spinner';
import { verifyCodeApi } from '../../api/auth';
import { toast, Slide } from 'react-toastify';


const schema = yup.object({
  token: yup.string().required().max(8).label("Verification Code"),
}).required()


export default function Verify () { 

  const navigate = useNavigate();
  const location = useLocation();
  const login_id = location.state;
  const clearWaitingQueue = () => {
    // Easy, right 😎
    toast.clearWaitingQueue();
  }



  const { register, 
    handleSubmit,
    setError,
    formState:{ errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = data => {
   
    setLoading(true)
    // post data to API
    console.log("d: ",data);
    verifyCodeApi(data).then((response) => {
      console.log(response.data);

      localStorage.setItem("RIFALYPUBLISHER", JSON.stringify(response.data.data));
      navigate('/reset-password',{replace:true,state:{token: data.token,login_id:login_id}});

    }).catch(error => {
      console.log(error)
      setLoading(false)

      if (error.response) {
        console.log(error.response.status)

        if(parseInt(error.response.status) === 401){

          setError("code", {
            type: "manual",
            message: "Invalid login ID and password Combination!",
          });

          console.log("User not found")
          toast.error(<div className='small'> Unauthorised ! <br /> Invalid Credentials. </div>, {
            position: "top-right",
            autoClose: 5000,
            transition: Slide,
            theme: "colored",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            draggable: true
          });
          clearWaitingQueue()
        }

        if (error.response.status === 422){

          setError("login_id", {
            type: "manual",
            message: "Invalid Email Address",
          });

          console.log(error.response)
        }



        if(error.response.status === 500){
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }

        
      }  else {
        console.log(error.message);
      }


    });
    // get response
    // if successfull store token in localstorage
    // redirect user to login

    console.log(data)

  };


    return (
      <HomeWrapper>
       <h3 className="text-black mb-3">
            Forgot Password ?
        </h3>
        <p className="text-muted mb-5">
          Enter the code that was sent to your <br />Email Address
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="token" className="form-label">
              Code
            </label>
            <input type="password" 
                   className={ errors.token ? "form-control is-invalid" : "form-control"} 
                   autoFocus 
                   placeholder="****"
                   {...register("token")} />

            <p className="errors">{errors.code?.message}</p>

          </div>


          <div className="form-group row">
            <div className="col-md-12">
              { loading ? (
                <span className="btn btn-primary">
                  <Loader
                    type="TailSpin" 
                    color="#ffffff"
                    height={20} 
                    width={20} />
                </span>
              ) : (
                <input className="btn btn-primary w-100" type="submit" value="Verify Code" />
              )}
            </div>


          </div>


          

          
        </form>

    
      </HomeWrapper>
    )

}

