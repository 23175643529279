import { fetchStaffDetailsApi } from "../../api/publisher";
import { fetchStaffsApi } from "../../api/user";

export const FETCH_STAFFS_LOADING = "FETCH_STAFFS_LOADING";
export const FETCH_STAFFS_SUCCESS = "FETCH_STAFFS_SUCCESS";
export const FETCH_STAFF_DETAILS_LOADING = "FETCH_STAFFS_DETAILS_LOADING";
export const FETCH_STAFF_DETAILS_SUCCESS = "FETCH_STAFFS_DETAILS_SUCCESS";



export const fetchStaffs = (id) => {
  return (dispatch) => {
    dispatch(fetchStaffsLoading());
    return fetchStaffsApi(id)
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchStaffsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};



export const fetchStaffDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchStaffDetailsLoading());
    return fetchStaffDetailsApi(id)
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchStaffDetailsSuccess(data[0]));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


//  staff 

const fetchStaffsLoading = () => {
  return {
    type: FETCH_STAFFS_LOADING,
  };
};


const fetchStaffsSuccess = (data) => {
  return {
    type: FETCH_STAFFS_SUCCESS,
    payload: data,
  };
};


//  staff details

const fetchStaffDetailsLoading = () => {
  return {
    type: FETCH_STAFF_DETAILS_LOADING,
  };
};

const fetchStaffDetailsSuccess = (data) => {
  return {
    type: FETCH_STAFF_DETAILS_SUCCESS,
    payload: data,
  };
};
