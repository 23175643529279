import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import HomeWrapper from '../../components/HomeWrapper'
import Loader from 'react-loader-spinner'
import { ForgotPasswordApi } from '../../api/auth'
import { toast, Slide } from 'react-toastify'

const schema = yup
  .object({
    login_id: yup
      .string()
      .email('Please Enter a valid Email Address')
      .required()
      .max(255)
      .label('Email Address or Phone'),
  })
  .required()

export default function Forgot () {

  console.log("forgot password")
  const navigate = useNavigate()

  const clearWaitingQueue = () => {
    // Easy, right 😎
    toast.clearWaitingQueue()
  }

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const [loading, setLoading] = useState(false)

  const onSubmit = data => {


    console.log("we are here")
    setLoading(true)
    // post data to API
    ForgotPasswordApi(data)
      .then(response => {
        console.log(response);
        console.log(response.data);

        // redirect 
        navigate("/verify-code",{replace:true,state:data.login_id});
      })
      .catch(error => {
        console.log(error)
        navigate("/verify-code");
        setLoading(false)

        if (error.response) {
          console.log(error.response.status)

          if (parseInt(error.response.status) === 401) {
            setError('email', {
              type: 'manual',
              message: 'Invalid login ID and password Combination!'
            })

            console.log('User not found')
            toast.error(
              <div className='small'>
                {' '}
                Unauthorised ! <br /> Invalid Credentials.{' '}
              </div>,
              {
                position: 'top-right',
                autoClose: 5000,
                transition: Slide,
                theme: 'colored',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                draggable: true
              }
            )
            clearWaitingQueue()
          }

          if (error.response.status === 422) {
            setError('login_id', {
              type: 'manual',
              message: 'Invalid Email Address'
            })

            console.log(error.response)
          }

          if (error.response.status === 500) {
            toast.error(error.response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            })
          }
        } else {
          console.log(error.message)
        }
      })
    // get response
    // if successfull store token in localstorage
    // redirect user to login

    console.log(data)
  }

  return (
    <HomeWrapper>
      <div className='container'>
        <div className='row'>
          <div className='col-md-10 m-center'>
            <h3 className='text-black mb-3'>Forgot Password ?</h3>
            <p className='text- mb-5'>
              That’s okay it happens! Enter your Email Address to reset your
              password
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='form-group'>
                <label htmlFor='login_id' className='form-label'>
                  Email Address
                </label>
                <input
                  type='email'
                  className={
                    errors.email ? 'form-control is-invalid' : 'form-control'
                  }
                  autoFocus
                  placeholder='Enter your Email Address'
                  {...register('login_id')}
                />

                <p className='errors'>{errors.email?.message}</p>
              </div>

              <div className='form-group row'>
                <div className='col-md-12'>
                  {loading ? (
                    <span className='btn btn-primary'>
                      <Loader
                        type='TailSpin'
                        color='#ffffff'
                        height={20}
                        width={20}
                      />
                    </span>
                  ) : (
                    <button className='btn btn-primary w-100' type='submit'>
                      Reset Password
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </HomeWrapper>
  )
}
