import React from 'react';
import {DateTime} from 'luxon';

export const REPORTCOLUMNS = [
  {
    id: 0,
    header: 'S/NO',
    accessor: 'id',
    Cell: props => <div>{(props.row.index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}</div>
  },
  {
    id: 1,
    header: 'Username',
    accessor: 'username',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 2,
    header: 'paper name',
    accessor: 'title',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 3,
    header: 'Download Type',
    accessor: 'readerType',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 4,
    header: 'Download Date',
    accessor: 'createdAt',
    Cell: props => <div className="text-capitalize">{(new Date(props.value)).toLocaleDateString()}</div>
  }

]
