import React from "react"
import './style.css';
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';





export default function DashboardWrapper({children}) {
  return (
    <div className="dashboard">
      <Navbar></Navbar>
      {/* <div className="main-page"> */}
        <Sidebar></Sidebar>
        <div className="main-content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
          {children}
        </div>
      {/* </div> */}
    
    </div>
  )
}