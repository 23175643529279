import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createPublisherApi } from "../../../../api/company";
import Loader from "react-loader-spinner";

const EditCompanyForm=(props)=>{
   var user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));
    var company = props.company;
    const [countries,setCountries] = useState([])
    const [isLoading,setIsLoading] = useState(false);
    const [companyData,setCompanyData] = useState(company)
    const [error,setError] = useState("")
    const companySizes = [{id:1,val:"1-10"},{id:2,val:"10-20"},{id:3,val:"20-50"},{id:4,val:"50 and above"}];
    var mydata = company;
    const handleInputChange=(e)=>{
        let name = e.target.name.toLowerCase();
        let val = e.target.value.trim();
        switch(name){
            case "companyname":
                mydata.companyName = val;
                break;
            case "companysize":
                mydata.companySize = val;
                break;
            case "address":
                mydata.address = val;
                break;
            case "city":
                mydata.city = val;
                break;
            case "country":
                mydata.country = val;
                break;
            case "commission":
                mydata.commission = val;
                break;
            default:
                mydata = company;
                break;
        }
        setCompanyData(mydata);
        console.log("mydata : ",mydata)
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        setError("")
        setIsLoading(true);
        let data = companyData
        data.user_id = user.id ? user.id : user.user_id;
console.log("x: ",data)
        createPublisherApi(data).then(result=>{
            console.log("result: ",result);
            props.onSave(result.data.data)
        }).catch(e=>{
            console.log("eeer: ",e);
            setError("something went wrong")
        })
        .finally(()=>{
            setIsLoading(false);
            
        })

    }

    useEffect(()=>{
        fetch("https://billing.rifaly.co.tz/billing/countries/all",{method:"GET",headers:{"Content-type":"application/json"}}).then(res=>res.json()).then(result=>{
            setCountries(result.data);
            
        }).catch(e=>{
            console.log(e);
        })
    },[])
    return(<section className="detail-section"> 
    <div className="detail-title">
      <div className="row">
        <div className="col">
          <h3>Basic Information</h3>
        </div>

      </div>
      

    </div>

    <div className="detail-contents">
        <form onSubmit={handleSubmit}>
      <div className="row">

        <div className="col-md-4">
          <div className="dc-title">
            Company Name
          </div>
          <div className="dc-body">
            <input type="text" name="companyName" className="form-control" placeholder="company name" defaultValue={companyData.companyName ? companyData.companyName:""} onChange={(e)=>handleInputChange(e)}/>
          </div>

        </div>


        <div className="col-md-4">
          <div className="dc-title">
            Company Size
          </div>
          <div className="dc-body">
          <select type="text" name="companySize" className="form-control" defaultValue={companyData.companySize ? companyData.companySize:1} onChange={(e)=>handleInputChange(e)}>
            {companySizes.map(cs=>{
                return <option key={cs.id} value={cs.id}>{cs.val}</option>
            })}
          </select>
          </div>

        </div>


        <div className="col-md-4">
          <div className="dc-title">
            Commission
          </div>
          <div className="dc-body">
          <input type="number" name="commission" className="form-control" placeholder="commission in %" defaultValue={companyData.commission ? companyData.commission:"0"} onChange={(e)=>handleInputChange(e)}/>
          </div>

        </div>


        <div className="col-md-4">
          <div className="dc-title">
            Company Address
          </div>
          <div className="dc-body">
          <input type="text" name="address" className="form-control" placeholder="company address" defaultValue={companyData.address ? companyData.address: ""} onChange={(e)=>handleInputChange(e)}/>
          </div>

        </div>


        <div className="col-md-4">
          <div className="dc-title">
            Country
          </div>
          <div className="dc-body">
          <select className="form-control" name="country" defaultValue={companyData.country? companyData.country: "TZ"} onChange={(e)=>handleInputChange(e)}>
            {countries.map(c=>{
                return <option key={c.countryCode} value={c.countryCode}>{c.name}</option>
            })}
          </select>
          </div>

        </div>



        <div className="col-md-4">
          <div className="dc-title">
            City
          </div>
          <div className="dc-body">
            <input type="text" name="city" className="form-control" placeholder="city" defaultValue={companyData.city ? companyData.city:""} onChange={(e)=>handleInputChange(e)}/>
          </div>

        </div>

        <div className="col-md-4">
          <div className="dc-body">{ isLoading ? (
                <span className="btn btn-primary">
                  <Loader
                    type="TailSpin" 
                    color="#ffffff"
                    height={20} 
                    width={20} />
                </span>
              ) :
            <input type="submit" name="submit" className="btn btn-primary" value={"SAVE"}/>}
          </div>

        </div>
        <div className="col-md-4">
          <div className="dc-title text-danger">
            {error}
          </div>
         
        </div>

      </div>
      </form>
    </div>


  </section>)
}
export default EditCompanyForm;