import React, { useEffect, useState } from "react";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import Navs from "../navs";
import PublicationTable from "./PublicationTable";
import { fetchPublicationsApi } from "../../../../api/publisher";
import { columns } from "./PublicationColumns";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ShareIcon } from "../../../../assets/img/share.svg";
import { ReactComponent as CancelIcon } from "../../../../assets/img/cancel.svg";
import { fetchPublications } from "../../../../redux-store/Actions/publication-action";

export default function Publications() {
  // const columns = PUBLICATIONCOLUMNS;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = JSON.parse(
    localStorage.getItem("RIFALYPUBLISHER")
  ).companyId;
  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isShared, setIsShared] = useState(true);

  const handleCancel = () => {
    setIsShared(false);
  };

  const fetchPublicationsData = async (companyId, currentPage) => {
    const response = await fetchPublicationsApi(companyId, currentPage);
    const publications =
      response && response.data.data ? response.data.data : [];
    return publications;
  };

  useEffect(() => {
    const fetchData = async () => {
      const publicationsData = await fetchPublicationsData(companyId, page);
      setData(publicationsData);
    };

    fetchData();
  }, [companyId, page]);

  const handlePageChange = async (newPage) => {
    if (newPage && newPage < 1) newPage = 1;
    setPage(newPage);
    const publicationsData = await fetchPublicationsData(companyId, newPage);
    setData(publicationsData);
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Publications
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Publications</h1>
              </div>
              <div>
                {isShared ? (
                  <div className="row share-body">
                    <div className="col-md-11">
                    <h6 className="share-title">
                      Hello <span className="bold">Publisher,</span>
                    </h6>

                    <h1 className="share-pretitle">
                      Now you can share your content to the world in one click.
                      Click (
                      <span>
                        {" "}
                        <ShareIcon />{" "}
                      </span>
                      ) after publishing to share
                    </h1>
                    </div>
                    <div className="col-auto d-flex align-items-center">
                    <CancelIcon
                      onClick={handleCancel}
                      style={{ cursor: "pointer" }}
                    />
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <div className="content">
              <Navs></Navs>

              <PublicationTable
                data={data?.publications || []}
                columns={columns}
                raw={data}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
