import { FETCH_PUBLICATIONS_SUCCESS, 
         FETCH_PUBLICATIONS_LOADING,
         FETCH_PUBLICATION_DETAILS_SUCCESS,
         FETCH_PUBLICATION_DETAILS_LOADING
        } from "../Actions/publication-action";


const initialState = {
  publications: [],
  isLoading: false,
};


export default function publicationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PUBLICATIONS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_PUBLICATIONS_SUCCESS: {
      return {
        ...state,
        publications: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}


const publicationInitialState = {
  publication: [],
  isLoading: false,
};



export function publicationDetailsReducer(state = publicationInitialState, action) {
  switch (action.type) {
    case FETCH_PUBLICATION_DETAILS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_PUBLICATION_DETAILS_SUCCESS: {
      return {
        ...state,
        publication: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

