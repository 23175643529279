import React, { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import HomeWrapper from '../../components/HomeWrapper';
import Loader from 'react-loader-spinner';
import { registerApi } from '../../api/auth';
import { toast, Slide } from 'react-toastify';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


const schema = yup.object({
  email: yup.string().email('Please Enter a valid Email Address').required().max(255).label("Email Address or Phone"),
  fullName: yup.string().required(),
  password: yup.string().required()
}).required()


export default function Register () { 

  const navigate = useNavigate();

  const clearWaitingQueue = () => {
    // Easy, right 😎
    toast.clearWaitingQueue();
  }



  const { register, 
    handleSubmit,
    setError,
    formState:{ errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState()

  const onSubmit = data => {
    setLoading(true)

    data.phone = phone;

    // post data to API
    registerApi(data).then((response) => {
      console.log(response.data);

      localStorage.setItem("RIFALYPUBLISHER", JSON.stringify(response.data.data));
      navigate('/overview');

    }).catch(error => {
      console.log(error)
      setLoading(false)

      if (error.response) {
        console.log(error.response.status)

        if(parseInt(error.response.status) === 401){

          setError("email", {
            type: "manual",
            message: "Invalid login ID and password Combination!",
          });

          console.log("User not found")
          toast.error(<div className='small'> Unauthorised ! <br /> Invalid Credentials. </div>, {
            position: "top-right",
            autoClose: 5000,
            transition: Slide,
            theme: "colored",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            draggable: true
          });
          clearWaitingQueue()
        }

        if (error.response.status === 422){

          setError("login_id", {
            type: "manual",
            message: "Invalid Email Address",
          });

          console.log(error.response)
        }


        if (error.response.status === 303){

          toast.error(error.response.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

          console.log(error.response)
        }



        if(error.response.status === 500){
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }

        
      }  else {
        console.log(error.message);
      }


    });
    // get response
    // if successfull store token in localstorage
    // redirect user to login

    console.log(data)

  };


    return (
      <HomeWrapper>
       <h2 className="text-black mb-3">
       Welcome to Rifaly  <br />
        for Publishers
      </h2>
        <p className="text-muted mb-5">
        Create an account or 
        <span className='text-primary pl-3 mx-2'>
            <NavLink to="/"  >Log in</NavLink>
          </span> 
        </p>

        

        <form onSubmit={handleSubmit(onSubmit)}>

        <div className="form-group">
            <label htmlFor="fullName" className="form-label">
              Full Name
            </label>
            <input type="fullName" 
                   className={ errors.fullName ? "form-control is-invalid" : "form-control"} 
                   autoFocus 
                   placeholder="Enter Full Name"
                   {...register("fullName")} />

            <p className="errors">{errors.fullName?.message}</p>

          </div>

          <div className="form-group">
                <label htmlFor="firstName" className="form-label">Phone Number</label>
                <div className="input-group">
                  <PhoneInput 
                    international
                    placeholder="Enter phone number"  
                    defaultCountry="TZ"
                    value={phone}
                    withCountryCallingCode="true"
                    countryCallingCodeEditable={false}
                    onChange={setPhone}/>
                </div>

                  <p className="errors">{errors.phone?.message}</p>

              </div>



          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input type="email" 
                   className={ errors.email ? "form-control is-invalid" : "form-control"} 
                   autoFocus 
                   placeholder="Enter Email Address"
                   {...register("email")} />

            <p className="errors">{errors.email?.message}</p>

          </div>

          <div className="form-group">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input type="password" 
                   className="form-control" 
                   placeholder="*************"
                   { ...register('password')}/>
          </div>

          <div className="form-group row">
            <div className="col-md-12">
              { loading ? (
                <span className="btn btn-primary">
                  <Loader
                    type="TailSpin" 
                    color="#ffffff"
                    height={20} 
                    width={20} />
                </span>
              ) : (
                <button className="btn btn-primary w-100" type="submit">Create Account</button>
              )}
            </div>


          </div>


          

          
        </form>

        <p className='text-center'>
          Forgot Password? 
          <span className='text-primary pl-3 mx-3'>
            <NavLink to="/forgot-password" >click here to reset</NavLink>
          </span> 
        </p>

      </HomeWrapper>
    )

}

