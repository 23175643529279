import { fetchPromotionsApi } from "../../api/promotion";

export const FETCH_PROMOTION_LOADING = "FETCH_PROMOTION_LOADING";
export const FETCH_PROMOTION_SUCCESS = "FETCH_PROMOTION_SUCCESS";



export const fetchPromotion = () => {
  return (dispatch) => {
    dispatch(fetchPromotionLoading());
    return fetchPromotionsApi()
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchPromotionSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


const fetchPromotionLoading = () => {
  return {
    type: FETCH_PROMOTION_LOADING,
  };
};


const fetchPromotionSuccess = (data) => {
  return {
    type: FETCH_PROMOTION_SUCCESS,
    payload: data,
  };
};