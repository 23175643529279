import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";

function SettingsNav() {
  let pid = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;

  return (
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <NavLink to="/settings/account" className="nav-link">
          Account
        </NavLink>
      </li>
    </ul>
  );
}

export default SettingsNav;
