import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import StaffEdit from "./StaffEdit";
import StaffDetail from "./StaffDetail";
import UpdatePublisherStaff from "./UpdatePublisherStaff";
import ConfirmDialog from "./confirmDialog";
const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));

const CustomOption = (props) => {
    const [showConfirm, setShowConfirm] = useState(false)
    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle
                    as="a"
                    className="dropdown-ellipses icon-dropdown"
                    id="dropdown-basic">
                    <i className="fe fe-more-horizontal"></i>
                </Dropdown.Toggle>
               
                    <Dropdown.Menu className="dropdown-menu-end">
                        <Dropdown.Item>
                            <StaffDetail placement="end" data={props.row.original} />
                    </Dropdown.Item>
                     {user.id != props.row.original.id ?<>
                        <Dropdown.Item>
                            <StaffEdit placement="end" data={props.row.original} />
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => setShowConfirm(true)}>Delete
                            {showConfirm ? <ConfirmDialog id={props.row.original.id} name={props.row.original.first_name}  show={showConfirm} onHide={() => setShowConfirm(false)} action="delete" /> : null}
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <UpdatePublisherStaff data={props.row.original}></UpdatePublisherStaff>
                        </Dropdown.Item></>: null}
                    </Dropdown.Menu> 
      </Dropdown>

    </div>
  );
};
export default CustomOption;
