import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL || "http://api.rifaly.co.tz/api/corporate/";

// function getHeaders() {
//   const user = localStorage.getItem("RIFALYADMIN");
//   const { token } = user ? JSON.parse(user) : {};
//   return {
//     Authorization: `Bearer ${token}`,
//   };
// }

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});


export const fetchCorporatesApi = ()=>{
  return api({
    url: `companies`,
    method: "GET",
  });
}

export const fetchCorporateByIdApi = (id)=>{
  return api({
    url: `companies/${id}`,
    method: "GET",
  });
}



export const createCorporatesApi = (data)=>{
  return api({
    url: `companies`,
    data: data,
    method: "POST",
  });
}

export const deleteCorporateApi = (id)=>{
  return api({
    url: `companies/`,
    data: id,
    method: "DELETE",
  });
}


export const updateCorporateStatusApi = (id, data)=>{
  return api({
    url: `companies/${id}`,
    data: data,
    method: "PATCH",
  });
}

export const updateCorporateCoverApi = (id, data)=>{
  return api({
    url: `companies/update-cover/${id}`,
    data: data,
    method: "PATCH",
  });
}





