import { fetchAdminsApi, fetchAdminDetailsApi } from "../../api/user";

export const FETCH_ADMINS_LOADING = "FETCH_ADMINS_LOADING";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMIN_DETAILS_LOADING = "FETCH_ADMIN_DETAILS_LOADING";
export const FETCH_ADMIN_DETAILS_SUCCESS = "FETCH_ADMIN_DETAILS_SUCCESS";



export const fetchAdmins = (id) => {
  return (dispatch) => {
    dispatch(fetchAdminsLoading());
    return fetchAdminsApi(id)
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchAdminsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};



export const fetchAdminDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchAdminDetailsLoading());
    return fetchAdminDetailsApi(id)
      .then((res) => {
        console.log(res.data)
        const data = (res.data.data) || [];
        dispatch(fetchAdminDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


//  staff 

const fetchAdminsLoading = () => {
  return {
    type: FETCH_ADMINS_LOADING,
  };
};


const fetchAdminsSuccess = (data) => {
  return {
    type: FETCH_ADMINS_SUCCESS,
    payload: data,
  };
};


//  staff details

const fetchAdminDetailsLoading = () => {
  return {
    type: FETCH_ADMIN_DETAILS_LOADING,
  };
};

const fetchAdminDetailsSuccess = (data) => {
  return {
    type: FETCH_ADMIN_DETAILS_SUCCESS,
    payload: data,
  };
};
