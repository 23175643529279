import { FETCH_READERS_LOADING, 
         FETCH_READERS_SUCCESS
        } from "../Actions/reader-action";


const initialState = {
  readers: [],
  isLoading: false,
};


export default function readerReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_READERS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_READERS_SUCCESS: {
      return {
        ...state,
        readers: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

