import React from 'react';
import {NavLink} from 'react-router-dom';

export default function Navs() {
  let pid = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;

  return (
    <ul className="nav nav-tabs">

      <li className="nav-item">
        <NavLink to="/reports/revenue" className="nav-link">Revenue</NavLink>
      </li>
           
      {parseInt(pid) === 6 ?
        <li className="nav-item">
          <NavLink to="/reports/corporate-subscriptions" className="nav-link">Corporate Subscriptions</NavLink>
        </li> : null}
      {parseInt( pid ) === 6 ?
        <li className="nav-item">
          <NavLink to="/reports/corporate-downloads" className="nav-link">Corporate Downloads</NavLink>
        </li> : null}
      {parseInt( pid ) === 6 ?
        <li className="nav-item">
          <NavLink to="/reports/directdownloads" className="nav-link">Direct Downloads</NavLink>
        </li> : null}
      {parseInt(pid) === 6 ?
        <li className="nav-item">
          <NavLink to="/reports/subscriptiondownloads" className="nav-link">Subscription Downloads</NavLink>
        </li> : null}
    </ul>
  )
}
