import { fetchIssuesApi, fetchIssueDetailsApi } from "../../api/publisher";

export const FETCH_ISSUES_LOADING = "FETCH_ISSUES_LOADING";
export const FETCH_ISSUES_SUCCESS = "FETCH_ISSUES_SUCCESS";
export const FETCH_ISSUE_DETAILS_LOADING = "FETCH_ISSUE_DETAILS_LOADING";
export const FETCH_ISSUE_DETAILS_SUCCESS = "FETCH_ISSUE_DETAILS_SUCCESS";



export const fetchIssues = (publisherId) => {
  return (dispatch) => {
    dispatch(fetchIssuesLoading());
    return fetchIssuesApi(publisherId)
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchIssuesSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


const fetchIssuesLoading = () => {
  return {
    type: FETCH_ISSUES_LOADING,
  };
};


const fetchIssuesSuccess = (data) => {
  return {
    type: FETCH_ISSUES_SUCCESS,
    payload: data,
  };
};


export const fetchIssueDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchIssueDetailsLoading());
    return fetchIssueDetailsApi(id)
      .then((res) => {
        console.log(res.data)
        const data = (res.data.data) || [];
        dispatch(fetchIssueDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


const fetchIssueDetailsLoading = () => {
  return {
    type: FETCH_ISSUE_DETAILS_LOADING,
  };
};

const fetchIssueDetailsSuccess = (data) => {
  return {
    type: FETCH_ISSUE_DETAILS_SUCCESS,
    payload: data,
  };
};


