import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchPublisherByIdApi } from "../../../api/company";
import DashboardWrapper from "../../../components/DashboardWrapper";
import SettingsNav from "./SettingsNav";
import UpdateCompanyCover from "../publishers/company/UpdateCompanyCover";
import Loader from "react-loader-spinner";
import EditCompanyForm from "../publishers/company/editCompany";
import EditBillingForm from "../publishers/company/editBilling";
import EditDocs from "../publishers/company/editDocs";
import api_config from "../../../api/api_config";

function Settings() {
  const navigate = useNavigate();
  const [changeVisibility, setChangeVisibility] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditBilling, setIsEditBilling] = useState(false);
  const [isEditDocs, setIsEditDocs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({});

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  function updateCompany(c) {
    if (c.company) {
      let u = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));
      u.companyId = c.company.id;
      localStorage.setItem("RIFALYPUBLISHER", JSON.stringify(u));
    }
    setIsEdit(false);
    setIsEditBilling(false);
    setIsEditDocs(false);
  }

  function showSize(size) {
    switch (size) {
      case 1:
        return "1 - 10";

      case 2:
        return "10 - 20";

      default:
        return "50 - Above";
    }
  }

  var id = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;

  useEffect(() => {
    if (id && id != 1) {
      setIsLoading(true);
      fetchPublisherByIdApi(id)
        .then((res) => {
          console.log(res);
          const data = res.data.data || [];
          setCompany(data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, isEdit, isEditBilling]);

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Settings
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Settings</h1>
              </div>
            </div>

            <div className="content">
              <SettingsNav></SettingsNav>

              <div className="row mt-5">
                <div className="col-md-12">
                  <div className="card">
                    {isLoading ? (
                      <div className="loader-holder d-flex justify-content-center align-items-center">
                        <Loader
                          type="BallTriangle"
                          heigth="100"
                          width="100"
                          color="green"
                          ariaLabel="loading-indicator"
                        />
                      </div>
                    ) : (
                      <div>
                        {company ? (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="px-4 pt-4">
                                <h6 className="header-pretitle">Overview</h6>

                                <h1 className="header-title">
                                  Company Information
                                </h1>

                                <hr />
                              </div>

                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="image-holder">
                                      <div className="img-place">
                                        <div
                                          className="img-cover"
                                          onMouseEnter={(e) => {
                                            setChangeVisibility(true);
                                          }}
                                          onMouseLeave={(e) => {
                                            setChangeVisibility(false);
                                          }}
                                        >
                                          <img
                                            src={
                                              api_config.STORAGE_URL +
                                              company.cover
                                            }
                                            className="img-fluid"
                                            alt={company.companyName}
                                          />
                                        </div>

                                        <div
                                          className={
                                            changeVisibility
                                              ? "img-change"
                                              : "img-change hidden"
                                          }
                                          onMouseEnter={(e) => {
                                            setChangeVisibility(true);
                                          }}
                                          onMouseLeave={(e) => {
                                            setChangeVisibility(false);
                                          }}
                                        >
                                          <UpdateCompanyCover
                                            name={company}
                                          ></UpdateCompanyCover>
                                          {/* <button className='btn btn-primary'>Change Image</button> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {isEdit ? (
                                  <EditCompanyForm
                                    isLoading={isLoading}
                                    onSave={(c) => updateCompany(c)}
                                    company={company}
                                  />
                                ) : (
                                  <section className="detail-section">
                                    <div className="detail-title">
                                      <div className="row">
                                        <div className="col">
                                          <h3>Basic Information</h3>
                                        </div>

                                        <div className="col-auto">
                                          {/* <Link to="/settings" className="btn-link">
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2" onClick={()=>{setIsEdit(true)}}>Edit</span>
                                        </Link> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="detail-contents">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Company Name
                                          </div>
                                          <div className="dc-body">
                                            {company.companyName}
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Company Size
                                          </div>
                                          <div className="dc-body">
                                            {showSize(company.companySize) ||
                                              "50 - Above"}
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Commission
                                          </div>
                                          <div className="dc-body">
                                            {company.commission}%
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Company Address
                                          </div>
                                          <div className="dc-body">
                                            {company.address}
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Country
                                          </div>
                                          <div className="dc-body">
                                            {company.country}
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">City</div>
                                          <div className="dc-body">
                                            {company.city}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                )}
                                {isEditDocs ? (
                                  <EditDocs
                                    company={company}
                                    onSave={(c) => updateCompany(c)}
                                  />
                                ) : (
                                  <section className="detail-section mt-4">
                                    <div className="detail-title">
                                      <div className="row">
                                        <div className="col">
                                          <h3>Business Infomation</h3>
                                        </div>

                                        <div className="col-auto">
                                          {/* <Link to="/settings" className="btn-link">
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2" onClick={()=>{setIsEditDocs(true)}}>Edit</span>
                                        </Link> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="detail-contents">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="dc-title">
                                            TIN Number {company.tin}
                                          </div>
                                          <div className="dc-body">
                                            <div className="dc-body_file">
                                              <div className="file-thumb">
                                                {/* <PdfThumb /> */}
                                                <img src="/file.png" alt="" />
                                              </div>

                                              <div className="dc-file-details">
                                                <p className="dc-file-details-title">
                                                  {company.publisher_attachment ? (
                                                    <a
                                                      target="_blank"
                                                      href={
                                                        api_config.STORAGE_URL +
                                                        company
                                                          .publisher_attachment
                                                          .tin
                                                      }
                                                    >
                                                      TIN Certificate
                                                    </a>
                                                  ) : (
                                                    "TIN Certificate"
                                                  )}
                                                </p>
                                                <p className="dc-file-details-size">
                                                  PDF
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-12 mt-4">
                                          <div className="dc-title">
                                            Business license*
                                          </div>
                                          <div className="dc-body">
                                            <div className="dc-body_file">
                                              <div className="file-thumb">
                                                {/* <PdfThumb /> */}
                                                <img src="/file.png" alt="" />
                                              </div>

                                              <div className="dc-file-details">
                                                <p className="dc-file-details-title">
                                                  {company.publisher_attachment ? (
                                                    <a
                                                      target="_blank"
                                                      href={
                                                        api_config.STORAGE_URL +
                                                        company
                                                          .publisher_attachment
                                                          .businessLicense
                                                      }
                                                    >
                                                      Business License
                                                    </a>
                                                  ) : (
                                                    "Business License"
                                                  )}
                                                </p>
                                                <p className="dc-file-details-size">
                                                  PDF
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <hr className="no-pad" />
                                        </div>

                                        <div className="col-md-12 mt-4">
                                          <div className="dc-title">
                                            Publishing Certificate
                                          </div>
                                          <div className="dc-body">
                                            <div className="dc-body_file">
                                              <div className="file-thumb">
                                                {/* <PdfThumb /> */}
                                                <img src="/file.png" alt="" />
                                              </div>

                                              <div className="dc-file-details">
                                                <p className="dc-file-details-title">
                                                  {company.publisher_attachment ? (
                                                    <a
                                                      target="_blank"
                                                      href={
                                                        api_config.STORAGE_URL +
                                                        company
                                                          .publisher_attachment
                                                          .publishingCertificate
                                                      }
                                                    >
                                                      Publishing Certificate
                                                    </a>
                                                  ) : (
                                                    "Publishing Certificate"
                                                  )}
                                                </p>
                                                <p className="dc-file-details-size">
                                                  {" "}
                                                  PDF
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <hr className="no-pad" />
                                        </div>

                                        <div className="col-md-12 mt-4">
                                          <div className="dc-title">
                                            Certificate Of Incorporation
                                          </div>
                                          <div className="dc-body">
                                            <div className="dc-body_file">
                                              <div className="file-thumb">
                                                {/* <PdfThumb /> */}
                                                <img src="/file.png" alt="" />
                                              </div>

                                              <div className="dc-file-details">
                                                <p className="dc-file-details-title">
                                                  {company.publisher_attachment ? (
                                                    <a
                                                      target="_blank"
                                                      href={
                                                        api_config.STORAGE_URL +
                                                        company
                                                          .publisher_attachment
                                                          .certificateOfIncorporation
                                                      }
                                                    >
                                                      Certificate of
                                                      Incorporation
                                                    </a>
                                                  ) : (
                                                    "Certificate of Incorporation"
                                                  )}
                                                </p>
                                                <p className="dc-file-details-size">
                                                  PDF
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <hr className="no-pad" />
                                        </div>

                                        <div className="col-md-12 mt-4">
                                          <div className="dc-title">
                                            National Id Letter
                                          </div>
                                          <div className="dc-body">
                                            <div className="dc-body_file">
                                              <div className="file-thumb">
                                                {/* <PdfThumb /> */}
                                                <img src="/file.png" alt="" />
                                              </div>

                                              <div className="dc-file-details">
                                                <p className="dc-file-details-title">
                                                  {company.publisher_attachment ? (
                                                    <a
                                                      target="_blank"
                                                      href={
                                                        api_config.STORAGE_URL +
                                                        company
                                                          .publisher_attachment
                                                          .nationalIdLetter
                                                      }
                                                    >
                                                      National ID Letter
                                                    </a>
                                                  ) : (
                                                    "National ID Letter"
                                                  )}
                                                </p>
                                                <p className="dc-file-details-size">
                                                  PDF
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <hr className="no-pad" />
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                )}
                                {isEditBilling ? (
                                  <EditBillingForm
                                    company={company}
                                    onSave={(c) => updateCompany(c)}
                                  />
                                ) : (
                                  <section className="detail-section mt-4">
                                    <div className="detail-title">
                                      <div className="row">
                                        <div className="col">
                                          <h3>Billing Information</h3>
                                        </div>

                                        <div className="col-auto">
                                          {/* <Link to="/settings" className="btn-link">
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2" onClick={()=>{setIsEditBilling(true)}}>Edit</span>
                                        </Link> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="detail-contents">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Bank Name
                                          </div>
                                          <div className="dc-body">
                                            {company.publisher_billing_info
                                              ? company.publisher_billing_info
                                                  .bankName
                                              : ""}
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Bank Branch
                                          </div>
                                          <div className="dc-body">
                                            {company.publisher_billing_info
                                              ? company.publisher_billing_info
                                                  .bankBranch
                                              : ""}
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Account Name
                                          </div>
                                          <div className="dc-body">
                                            {company.publisher_billing_info
                                              ? company.publisher_billing_info
                                                  .accountName
                                              : ""}
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Account Number
                                          </div>
                                          <div className="dc-body">
                                            {company.publisher_billing_info
                                              ? company.publisher_billing_info
                                                  .accountNumber
                                              : ""}
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Swift Code
                                          </div>
                                          <div className="dc-body">
                                            {company.publisher_billing_info
                                              ? company.publisher_billing_info
                                                  .swiftCode
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Settings;
