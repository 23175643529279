import React, { useState } from 'react';
import { useAsyncDebounce } from "react-table";

function ReportFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {

  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);


  return (
      <div className='row m-0'>
        
        <div className="col-auto">
          <div className='input-group input-group-merge input-group-reverse'>
            <input  type="text" 
                    value={value || ""}
                    onChange={(e) => {
                      setValue(e.target.value);
                      onChange(e.target.value);
                    }}
                    className='form-control' placeholder='Search Reports' />
            <div className="input-group-text" id="inputGroupReverse">
              <span className="fe fe-search"></span>
            </div>
          </div>
        </div>
       
      </div>
  );
}

export default ReportFilter;
